<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">

        </div>
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Gelirler</h6>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <el-table ref="tableRef" row-key="date" :data="tableData" style="width: 100%">
                  <el-table-column prop="date" label="Tarih">
                  </el-table-column> 
                  <el-table-column prop="totalAdvisee" label="Danışan Sayısı">
                  </el-table-column>
                  <el-table-column prop="totalPayment" label="Ödemeler">
                    <template #default="scope">
                      <div>{{ scope.row.totalPayment + ' ₺' }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="changeRate" label="Değişim Oranı">
                    <template #default="scope">
                      <div v-if="scope.row.changeRate">{{ Number(scope.row.changeRate).toFixed(2) }}</div>
                      <div v-else><span>-</span> </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import getPaymentReport from "../services/paymentService";
import moment from "moment";


export default {
  name: "payment-report",
  async mounted() {
    const today = moment();
    const startDate = moment().subtract(1, 'year').startOf('month');
    const response = await getPaymentReport(startDate.format('YYYY-MM-DD'), today.format('YYYY-MM-DD'));
    this.tableData = response.data.map((x) => {
      return {
        date: moment(x.date, "YYYY-M").format("MMMM-YYYY"),
        totalAdvisee: x.totalAdvisee,
        totalPayment: x.totalPayment,
        changeRate: x.changeRate
      }
    }).reverse();
  },
  data() {
    return {
      tableData: [],
      labels: [],
      values: []
    };
  },
  methods: {
    getLabels() {
      return this.labels;
    }
  },
  components: {
    GradientLineChart,

  },
};
</script>
