<template>
  <div class="py-4 container-fluid" style="min-height: 100vh !important;">
    <div class="row">
      <div style="display: flex;justify-content: end;margin-bottom: 10px;">
        <argon-button color="primary" variant="gradient" active="active" @click="addNew">Yorum İste</argon-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="height: 100vh;">
        <el-table :data="advisee.items" style="width: 100%; min-height: 400px;" table-layout="auto"
          @current-change="handleTableRowClick" v-loading="loading">
          <el-table-column prop="status" label="Durum">
            <template #default="scope">
              <el-tag v-if="scope.row.status == 1" :type="scope.row.status === 1 ? 'success' : ''"
                disable-transitions>Yayında</el-tag>
              <el-tag v-else-if="scope.row.status == 2" :type="scope.row.status === 2 ? 'warning' : ''"
                disable-transitions>Yorum Bekleniyor</el-tag>
              <el-tag v-else :type="scope.row.status === 3 ? 'info' : ''" disable-transitions>Yayınlanmadı</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Danışan" width="180" />
          <el-table-column prop="creationDateTime" label="Tarih" />
          <el-table-column label="Puan">
            <template #default="scope">
              <el-rate disabled v-model="scope.row.star"></el-rate>
            </template>
          </el-table-column>
          <el-table-column prop="text" label="Yorum">
            <template v-slot="{ row }">
              <div class="ellipsis-text">{{ row.text }}</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="my-pagination" v-model:currentPage="activePage" @current-change="handlePaginationClick"
          background layout="prev, pager, next" :total="advisee.totalCount" @input="fetchData" />
      </div>
    </div>
  </div>
  <el-dialog v-model="dialogVisible" title="Yorum İste" width="30%" :before-close="handleClose">
    <div class="row col-md-12">
      <span>Yorum bağlantısı sms ile danışanınıza iletilecek.</span>
    </div>
    <div class="row mt-3 col-md-12">
      <el-select id="advisee_name_filter" v-model="selectedAdvisee" filterable remote size="large" reserve-keyword
        placeholder="İsim giriniz" :remote-method="getAdviseesBySearchKey" :loading="loading" style="width: 100%">
        <el-option v-for="item in adviseeApiResult" :key="item.id" :label="item.name" :value="item">
        </el-option>
      </el-select>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">İptal</el-button>
        <el-button type="primary" @click="sendCommentRequest">
          Gönder
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

import ArgonButton from "@/components/ArgonButton";


export default {
  name: "comment",
  components: {
    ArgonButton
  },
  data() {
    return {
      activePage: 1,
      loading: true,
      adviseeApiResult: {},
      selectedAdvisee: null,
      advisee: {
        tableTitle: "Danışanlar",
        column1: "Ad Soyad",
        column2: "Durum",
        column3: "Paket",
        column4: "Başlangıç Tarihi",
        items: [],
        totalCount: 0,
        totalPages: 0,

      },
      dialogVisible: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePaginationClick() {
      this.fetchData()
    },
    handleTableRowClick(currentRow) {
      this.$router.push(currentRow.detailLink)
    },
    async fetchData() {
      this.loading = true;
      this.$http
        .get(`/comment?page=${this.activePage - 1}`)
        .then((response) => {
          console.log(response.data.data)
          const arr = []
          response.data.data.forEach((item) => {
            const creationDateTime = new Date(item.creationDateTime).toLocaleDateString('en-GB').replaceAll("/", ".")
            const advisee = {
              id: item.id,
              name: item.adviseeName + " " + item.adviseeSurname,
              text: item.text,
              star: item.star,
              creationDateTime: creationDateTime,
              detailLink: "/comment/" + item.id,
              status: item.status
            }
            arr.push(advisee)
          })
          this.advisee.items = arr
          this.advisee.totalCount = response.data.totalCount
          this.advisee.totalPages = response.data.totalPages
          console.log(this.advisee)
          this.loading = false;

        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    addNew() {
      this.dialogVisible = true
    },
    sendCommentRequest() {
      this.dialogVisible = false
      this.$http
        .post(`/comment/request`, {
          "adviseeId": this.selectedAdvisee.id
        }).then(() =>{
          this.fetchData()
        })
  
    },
    tooltipMethod() {
      return null
    },
    getAdviseesBySearchKey(searchKey) {
      this.$http
        .get(`/advisee?searchKey=${searchKey}`)
        .then((response) => {
          this.adviseeApiResult = response.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name + " " + item.surname,
              logo: item.type == 1 ? "fas fa-globe" : "fas fa-people-arrows",
              phone: item.phone,
              status: item.status == 1 ? 'Aktif' : 'Pasif',
              package: item.planName,
              startDate: item.planStartDate,
              detailLink: "/advisee/" + item.id
            }
          })
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
  }
};
</script>
