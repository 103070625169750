<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="scrollable-section">
      <div class="row">
        <div class="col-lg-12 mb-lg">
          <div class="card z-index-2">
            <div class="card">
              <div class="card-header pb-0">
                <div class="d-flex align-items-center justify-content-center">
                  <el-avatar :size="100" style="font-size: 50px; margin-right: 10px; align-items: center;" :src="circleUrl">B</el-avatar>
                  <el-button>Yeni Resim</el-button>
                </div>
              </div>
              <div class="card-body">
      
                <div class="row">
                  <div class="col-md-2">
                    <label for="example-text-input" class="form-control-label">Ünvan</label>
                    <el-select style="width: 100%;" :disabled="isFormEditable()" value-key="id"
                      v-model="adviseeDetail.gender" size="large">
                      <el-option v-for="item in genderOptions" :key="item.id" :label="item.label" :value="item.value" />
                    </el-select>
                  </div>
                  <div class="col-md-5">
                    <label for="example-text-input" class="form-control-label">Ad</label>
                    <el-input v-model="this.adviseeDetail.name" size="large" :disabled="isFormEditable()" />
                  </div>
                  <div class="col-md-5">
                    <label for="example-text-input" class="form-control-label">Soyad</label>
                    <el-input v-model="this.adviseeDetail.surname" size="large" :disabled="isFormEditable()" />
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">İletişim Bilgileri</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Telefon</label>
                    <el-input v-model="this.adviseeDetail.phone" size="large" :disabled="isFormEditable()" maxlength="10">
                      <template #prepend>+90</template>
                    </el-input>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">E-mail</label>
                    <el-input v-model="this.adviseeDetail.email" size="large" :disabled="isFormEditable()" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Instagram</label>
                    <el-input v-model="this.adviseeDetail.email" size="large" :disabled="isFormEditable()" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Doktor Takvimi</label>
                    <el-input v-model="this.adviseeDetail.email" size="large" :disabled="isFormEditable()" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">İl</label>
                    <el-select style="width: 100%;" v-model="this.adviseeDetail.cityId"  value-key="id" :disabled="isFormEditable()"
                      @change="onChangeCitySelect" size="large">
                      <el-option id="citySelect" v-for="item in cities" :key="item.id" :label="item.name"
                        :value="item.id" />
                    </el-select>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">İlçe</label>
                    <el-select style="width: 100%;" value-key="id" :disabled="isFormEditable()" v-model="this.adviseeDetail.countyId"
                      size="large">
                      <el-option id="countySelect" v-for="item in counties" :key="item.id" :label="item.name"
                        :value="item.id" />
                    </el-select>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Mahalle</label>
                    <el-select style="width: 100%;" value-key="id" :disabled="isFormEditable()" v-model="this.adviseeDetail.countyId"
                      size="large">
                      <el-option id="countySelect" v-for="item in counties" :key="item.id" :label="item.name"
                        :value="item.id" />
                    </el-select>
                  </div>
                  <div class="col-md-6">
                    <GMapMap :options="options" :center="center" :zoom="18" style="height: 200px; width: 100%;">
                    <GMapCluster>
                      <GMapMarker :position="center" :clickable="false" :draggable="false">
                      </GMapMarker>
                    </GMapCluster>
                  </GMapMap>
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Açık Adres</label>
                    <el-input v-model="adviseeDetail.note" :autosize="{ minRows: 3, maxRows: 6 }" type="textarea"
                      :disabled="!formEditable" />
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Özgeçmiş</p>
                <div class="row">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Hakkında</label>
                    <el-input v-model="adviseeDetail.note" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea"
                      :disabled="!formEditable" />
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">İlgi Alanları</label>
                    <el-input v-model="adviseeDetail.note" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea"
                      :disabled="!formEditable" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";




const body = document.getElementsByTagName("body")[0];

export default {
  name: "advisee-detail",

  data() {
    return {
      showMenu: false,
      formEditable: false,
      formEditableButtonText: "Düzenle",
      adviseeId: this.$route.params.id,
      packageHistories: [],
      paymentHistories: [],
      measurementHistories: [],
      adviseeDetail: {},
      cities: [],
      counties: [],
      genderOptions: [
        {
          "id": 0,
          "label":"Kadın",
          "value": 0
        },
        {
          "id": 1,
          "label":"Erkek",
          "value": 1
        }
      ]
    };
  },
  created() {
    this.fetchAdviseeDetailData()
    this.fetchCityData();
  },
  methods: {
    isFormEditable: function () {
      if (this.formEditable) {
        return false
      }
      return true
    },
    setFormEditable: function () {
      if (this.formEditableButtonText === "Kaydet") {
        console.log("call save service")
        this.updateAdvisee()
        this.formEditable = false
        this.formEditableButtonText = "Düzenle"
        return
      }
      this.fetchCityData()
      this.fetchCountyData(this.adviseeDetail.cityId)
      this.formEditable = true
      this.formEditableButtonText = "Kaydet"
    },
     fetchAdviseeDetailData() {
      this.$http
        .get(`/advisee/${this.adviseeId}`)
        .then((response) => {
          this.adviseeDetail = response.data
          this.fetchCountyData(this.adviseeDetail.cityId)
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
     fetchCityData() {
      this.$http
        .get("/city")
        .then((response) => {
          console.log(response.data)
          this.cities = response.data
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
     fetchCountyData(cityId) {
      this.$http
        .get(`/county/${cityId}`)
        .then((response) => {
          console.log(response.data)
          this.counties = response.data
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
     onChangeCitySelect(cityId) {
      this.adviseeDetail.countyId=null
      this.fetchCountyData(cityId)
    },
    updateAdvisee() {
      this.$http
        .put(`/advisee/${this.adviseeId}`, {
          "email": this.adviseeDetail.email,
          "name": this.adviseeDetail.name,
          "surname": this.adviseeDetail.surname,
          "gender": this.adviseeDetail.gender,
          "birthday": this.adviseeDetail.birthday,
          "phone": this.adviseeDetail.phone,
          "cityId": this.adviseeDetail.cityId,
          "countyId": this.adviseeDetail.countyId,
          "note": this.adviseeDetail.note,
        })
    },



  },
  components: {},

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
