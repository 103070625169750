<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="scrollable-section">
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <div class="card z-index-2">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="d-flex align-items-center col-m-3 mb-0 ">
                    <h6 class="font-weight-bold mr-3">Yorum Bilgileri</h6>
                    <el-switch size="large" v-model="selectedStatus" :disabled="disableSwitch" class="ml-2" inline-prompt
                      style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949; margin-left: 10px;" active-text="Yayında"
                      inactive-text="Yayında Değil" @change="changeEvent" />
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Ad</label>
                      </el-row>
                      <el-text class="mx-1" size="default">{{ commentDetail.adviseeName }}</el-text>
                    </div>
                    <div class="col-md-6">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Soyad</label>
                      </el-row>
                      <el-text class="mx-1" size="default">{{ commentDetail.adviseeSurname }}</el-text>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-3">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Telefon Numarası</label>
                      </el-row>
                      <el-text class="mx-1" size="default">{{ commentDetail.adviseePhoneNumber }}</el-text>
                    </div>
                    <div class="col-md-6 mt-3">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Tarih</label>
                      </el-row>
                      <el-text class="mx-1" size="default">{{ commentDetail.creationDateTime }}</el-text>
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                  <div class="row">
                    <div class="col-md-12">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Puan</label>
                      </el-row>
                      <el-rate disabled class="mx-1" v-model="commentDetail.star"></el-rate>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Yorum</label>
                      </el-row>
                      <el-text class="mx-1" size="default">{{ commentDetail.text }}</el-text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";



const body = document.getElementsByTagName("body")[0];

export default {
  name: "comment-detail",

  data() {
    return {
      commentId: this.$route.params.id,
      initialLoad:true,
      commentDetail: {},
      selectedStatus: null,
      disableSwitch:false,
      commentStatuses: [
        {
          status: 1,
          text: "Yayında",
          color: "success"
        },
        {
          status: 3,
          text: "Yayınlanmadı",
          color: "info"
        },
        {
          status: 2,
          text: "Yorum Bekleniyor",
          color: "warning"
        }

      ]
    };
  },
  created() {
    this.fetchCommentDetailData()
  },
  methods: {
    fetchCommentDetailData() {
      this.$http
        .get(`/comment/${this.commentId}`)
        .then((response) => {
          this.commentDetail = response.data
          this.commentDetail.creationDateTime = new Date(this.commentDetail.creationDateTime).toLocaleDateString('en-GB').replaceAll("/", ".")
          for (let i = 0; i < this.commentStatuses.length; i++) {
            if (this.commentStatuses[i].status == response.data.status) {
              this.selectedStatus = this.commentStatuses[i]
              if (this.selectedStatus.status == 2) {
                this.disableSwitch=true;
              }
              if(this.selectedStatus.status==1) {
                this.selectedStatus = true
              }
              if(this.selectedStatus.status==3) {
                this.selectedStatus = false
              }

              break
            }

          }
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    changeCommentStatus(isPublished) {
      this.$http
        .post(`/comment/update-status`, {
          "commentId": this.commentId,
          "status": isPublished ? 1 : 2,
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    renderOption(item) {
      return `${item.text} - ${item.color}`;
    },
    changeEvent(e) {
      if (this.initialLoad) {
        this.initialLoad = false;
        return;
      }
      this.changeCommentStatus(e)
    }
  },
  components: {},

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
