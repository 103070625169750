import httpService from './httpService';

const getPaymentReport = (startDate, endDate) => {
    return httpService
        .get(`/payment-log/report?startDate=${startDate}&endDate=${endDate}`)
        .catch((error) => {
            console.error('Hata:', error);
        });
}


export default getPaymentReport 