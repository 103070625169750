<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Kayıt ol</h4>
                  <p class="mb-0">Kayıt olabilmek için lütfen email ve şifre bilgilerini gir.</p>
                </div>
                <div class="card-body">
                  <div> 
                    <argon-alert v-if="error" color="danger"> {{ error }} </argon-alert>
                   </div>
                    <div class="mb-3">
                      <argon-input type="email" placeholder="Email" name="email" size="lg" @input="updateEmail" />
                    </div>
                    <div class="mb-3">
                      <argon-input type="password" placeholder="Şifre" name="password" size="lg" @input="updatePassword" />
                    </div>
                    <div class="mb-3">
                      <argon-input type="password" placeholder="Şifre tekrar" name="updatePassword" size="lg" @input="updateRepeatPassword" />
                    </div>
                    <div class="mb-3">
                    </div>

                    <div class="text-center" >
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        @click="signUp"
                      >Kayıt ol</argon-button>
                    </div>

                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                   Zaten hesabınız var mı?
                    <a
                      href="/signin"
                      class="text-success text-gradient font-weight-bold"
                    >Giriş yap</a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
                background-size: cover;"
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Attention is the new currency"</h4>
                <p
                  class="text-white position-relative"
                >The more effortless the writing looks, the more effort the writer actually put into the process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert";
import { Auth } from 'aws-amplify';


const body = document.getElementsByTagName("body")[0];

function hasNumber(myString) {
  return /\d/.test(myString);
}
  
export default {
  data() {
    return {
      email: '',
      password: '',
      repeatPassword: '',
      error: ''
    }
  },
  name: "signin",
  components: {
    ArgonInput,
    ArgonButton,
    ArgonAlert,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    updateEmail(event) {
      this.email = event.target.value;
    },
    updatePassword(event) {
      this.password = event.target.value;
    },
    updateRepeatPassword(event) {
      this.repeatPassword = event.target.value;
    },
    async signUp(event) {
      event.preventDefault()
      if (!this.email.includes('@')) {
        this.error = 'Email adresi geçersiz.'
        return
      }
      if (this.password != this.repeatPassword) {
        this.error = 'Şifreler birbiri ile eşleşmiyor.'
        return 
      }
      if (this.password.length < 8) {
        this.error = 'Şifre 8 hane ve üzeri uzunlukta olmalıdır.'
        return
      }
      if (!hasNumber(this.password)) {
        this.error = 'Şifre en az bir adet rakam içermelidir.'
        return
      }

      try {
        await Auth.signUp({
          username: this.email,
          password: this.password
        });
        this.$store.dispatch('setEmail', this.email);
        this.$router.push("/confirmcode")
      } catch (error) {
        if (error.name === "UsernameExistsException") {
          this.error = 'Bu kullanıcı adı ile zaten bir kullanıcı  mevcut.'
        }
      }
      
    }
  }
};
</script>
