<template>
  <div class="card widget-calendar h-100 resizable-calendar">
    <!-- Card header -->
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ title }}</h6>
    </div>
    <!-- Card body -->
    <div class="p-3 card-body">
      <div v-if="!uiState.isCalendarLoading" data-toggle="widget-calendar" id="widget-calendar"></div>
    </div>
    <el-dialog v-model="uiState.isNewAppointmentDialogOpen" :beforeClose="cleanFormState" title="Yeni Randevu Oluştur">
      <el-form label-position="top">
        <el-form-item>
          <el-form-item label="Ad" v-if="uiState.showNewAdviseeFieldsInNewAppointmentDialog">
            <el-input v-model="newAdviseeRecord.name" size="large" />
          </el-form-item>
          <el-form-item style="margin-left: 10px;" label="Soyad"
            v-if="uiState.showNewAdviseeFieldsInNewAppointmentDialog">
            <el-input v-model="newAdviseeRecord.surname" size="large" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="Ad Soyad" v-if="!uiState.showNewAdviseeFieldsInNewAppointmentDialog">
          <el-col :span="8">
            <el-select id="advisee_name_filter" v-model="selectedAdvisee" filterable remote size="large" reserve-keyword
              placeholder="Aramaya başlayın..." :remote-method="getAdviseesBySearchKey" :loading="loading"
              style="width: 100%">
              <el-option v-for="item in adviseeApiResult" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
              <template v-slot:empty>
                <el-button style="width: 100%;" type="primary" text
                  @click="uiState.showNewAdviseeFieldsInNewAppointmentDialog = true">+ Yeni
                  Danışan
                  Ekle</el-button>
              </template>
            </el-select>
          </el-col>
          <el-col :span="16">
            <el-button type="primary" text @click="uiState.showNewAdviseeFieldsInNewAppointmentDialog = true">+ Yeni
              Danışan
              Ekle</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="Telefon" v-if="uiState.showNewAdviseeFieldsInNewAppointmentDialog"
          prop="newAdviseeRecord.phone">
          <el-input type="tel" v-model="newAdviseeRecord.phone" size="large"
            style="width: 35%;">
            <template #prepend>+90</template>
          </el-input>
        </el-form-item>
        <el-form-item label="Randevu Tarihi" style="font-weight: bold;">
          <el-col :span="8">
            <el-date-picker v-model="selectedDate" type="date" size="large" format="DD-MM-YYYY" />
          </el-col>
          <el-col :span="8">
            <el-time-select v-model="selectedTime" start="08:30" step="00:15" end="19:30" size="large" style="font-weight: normal;" />
          </el-col>
          <el-col :span="8">
            <el-select v-model="elapsedTime" size="large" style="font-weight: normal;">
              <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item" style="font-weight: normal;" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="Hizmet Tipi">
          <el-radio-group v-model="appointmentType" size="large">
            <el-radio-button v-for="at in appointmentTypes" :key="at.value" :label="at.value" name="type">
              {{ at.label }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="uiState.isNewAppointmentDialogOpen = false;">İptal</el-button>
          <el-button v-if="!uiState.showNewAdviseeFieldsInNewAppointmentDialog" type="primary"
            @click="addAppointmentToCalendar">
            Kaydet
          </el-button>
          <el-button v-if="uiState.showNewAdviseeFieldsInNewAppointmentDialog" type="primary"
            @click="addAppointmentWithAdvisee">
            Kaydet
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="uiState.isUpdateAppointmentDialogOpen" title="Randevuyu Güncelle">
      <el-form :model="formUpdate" label-position="top">
        <el-form-item label="Ad Soyad">
          <span>{{ this.selectedEvent.title }}</span>
        </el-form-item>
        <el-form-item label="Randevu Tarihi">
          <el-col :span="8">
            <el-date-picker v-model="selectedDate" type="date" size="large" format="DD-MM-YYYY" />
          </el-col>
          <el-col :span="8">
            <el-time-select v-model="selectedTime" start="08:30" step="00:15" end="19:30" size="large" />
          </el-col>
          <el-col :span="8">
            <el-select v-model="elapsedTime" size="large">
              <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="Hizmet Tipi">
          <el-radio-group v-model="appointmentType" size="large">
            <el-radio-button v-for="at in appointmentTypes" :key="at.value" :label="at.value" name="type">
              {{ at.label }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="danger" @click="deleteAppointmentFromCalendar">Sil</el-button>
          <el-button @click="uiState.isUpdateAppointmentDialogOpen = false;">İptal</el-button>
          <el-button type="primary" @click="updateAppointmentInCalendar">
            Güncelle
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>

import { Calendar } from "@fullcalendar/core";
import timeGridPlugin from '@fullcalendar/timegrid';
import trLocale from "@fullcalendar/core/locales/tr";
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import { ElLoading } from 'element-plus'
import {
  createAppointment, createAppointmentAndAdvisee, deleteAppointment,
  updateAppointment, getAppointments, getAppointmentTimeOptions, getAppointmentTypes
} from '../services/appointmentService';
import { searchAdviseeByName } from '../services/adviseeService';


export default {
  name: "appointment",
  props: {
    title: {
      type: String,
    }
  },
  data() {
    return {
      newAdviseeRecord: {
        name: null,
        surname: null,
        phone: null
      },
      uiState: {
        isCalendarLoading: false,
        isNewAppointmentDialogOpen: false,
        isUpdateAppointmentDialogOpen: false,
        showNewAdviseeFieldsInNewAppointmentDialog: false,
      },
      timeOptions: getAppointmentTimeOptions(),
      appointmentTypes: getAppointmentTypes(),
      //buraya kadar refactor edildi.
      selectedSlot: { startDateTime: null, endDateTime: null },
      adviseeApiResult: {},
      loading: false,
      selectedAdvisee: "",
      calendar: {},
      selectedDate: new Date(),
      selectedTime: "08:30",
      elapsedTime: { "label": "15 dakika", "value": 15 },
      appointmentType: null,

      selectedEvent: {},
    };
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.calendar = new Calendar(document.getElementById("widget-calendar"), {
      plugins: [timeGridPlugin, interactionPlugin],
      initialView: "timeGridWeek",
      eventTextColor: "#343a40",
      eventMinHeight: 10,
      eventShortHeight: 10,
      height: "100vh",
      locale: trLocale,
      firstDay: 1,
      allDaySlot: false,
      droppable: false,
      headerToolbar: {
        left: 'today,prev,next',
        center: 'title',
        right: 'timeGridWeek,timeGridDay' // user can switch between the two
      },
      selectable: true,
      editable: false,
      initialDate: new Date().toISOString().slice(0, 10),
      stickyHeaderDates: true,
      slotDuration: "00:15",
      slotMinTime: "07:00",
      slotMaxTime: "21:00",
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false
      },
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: false,
        hour12: false
      },
      eventClick: function (info) {
        info.jsEvent.preventDefault();
        this.selectedDate = new Date(info.event.startStr)
        this.selectedTime = String(this.selectedDate.getHours()).padStart(2, '0') + ":" + String(this.selectedDate.getMinutes()).padStart(2, '0')
        const endDate = new Date(info.event.endStr)
        var differenceValue = (endDate.getTime() - this.selectedDate.getTime()) / 1000;
        differenceValue /= 60;
        for (let index = 0; index < this.timeOptions.length; index++) {
          const element = this.timeOptions[index];
          if (element.value == differenceValue) {
            this.elapsedTime = element.value
            break
          }
        }

        this.appointmentType = info.event.extendedProps.type
        this.selectedEvent = info.event
        this.uiState.isUpdateAppointmentDialogOpen = true
      }.bind(this),
      select: function (info) {
        this.selectedDate = info.start
        this.selectedTime = String(info.start.getHours()).padStart(2, '0') + ":" + String(info.start.getMinutes()).padStart(2, '0')
        this.uiState.isNewAppointmentDialogOpen = true
      }.bind(this),
      loading: function (isLoading) {
        const loading = ElLoading.service({
          lock: true,
          text: 'Yükleniyor',
        })
        if (!isLoading) {// isLoading gives boolean value
          loading.close()
        }
      },
      views: {
        month: {
          titleFormat: {
            month: "long",
            year: "numeric",
          },
        },
        agendaWeek: {
          titleFormat: {
            month: "long",
            year: "numeric",
            day: "numeric",
          },
        },
        agendaDay: {
          titleFormat: {
            month: "short",
            year: "numeric",
            day: "numeric",
          },
        },
      },
    },
    );
    this.calendar.addEventSource(this.fetchAppointmentData)
    this.calendar.render();
    this.handleResize()
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 768) {
        this.calendar.changeView('timeGridDay');
      } else {
        this.calendar.changeView('timeGridWeek');
      }
    },
    addAppointmentToCalendar() {
      createAppointment(this.selectedDate, this.selectedTime, this.elapsedTime.value,
        this.appointmentType, this.selectedAdvisee)
        .then(() => {
          this.calendar.refetchEvents();
        })
        .finally(() => {
          this.cleanFormState();
        })
    },
    addAppointmentWithAdvisee() {
      createAppointmentAndAdvisee(this.selectedDate, this.selectedTime, this.elapsedTime.value,
        this.selectedAdvisee, this.appointmentType, this.newAdviseeRecord)
        .then(() => {
          this.calendar.refetchEvents();
        })
        .finally(() => {
          this.cleanFormState();
        });
    },
    deleteAppointmentFromCalendar() {
      deleteAppointment(this.selectedEvent.id)
        .then(() => {
          this.calendar.refetchEvents();
        })
        .finally(() => {
          this.cleanFormState();
        })
    },
    updateAppointmentInCalendar() {
      updateAppointment(this.selectedDate, this.selectedTime, this.elapsedTime, this.selectedEvent.id, this.selectedAdvisee, this.appointmentType)
        .then(() => {
          this.calendar.refetchEvents();
        })
        .finally(() => {
          this.cleanFormState();
        })
    },
    fetchAppointmentData(info, successCallback) {
      getAppointments(info)
        .then((appointment) => {
          successCallback(appointment)
        })
    },
    getAdviseesBySearchKey(searchKey) {
      this.loading=true
      if (searchKey != null) {
        searchAdviseeByName(searchKey)
        .then((response) => {
          this.adviseeApiResult = response
          this.loading=false
        })
      }
    },
    cleanFormState() {
      this.selectedAdvisee = ""
      this.uiState.showNewAdviseeFieldsInNewAppointmentDialog = false
      this.uiState.isNewAppointmentDialogOpen = false
      this.uiState.isUpdateAppointmentDialogOpen = false
      this.newAdviseeRecord = {
        name: null,
        surname: null,
        phone: null
      }
      this.elapsedTime = { "label": "15 dakika", "value": 15 }
    }
  }
};
</script>
