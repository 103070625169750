<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <h6>{{ title }}</h6>
      <p v-if="changeRate < 0" class="text-sm">
        <i  class="fa fa-arrow-down text-danger"></i>
        <span class="font-weight-bold"> %{{ changeRate }}</span>
        {{ detail2 }} düşüş
      </p>
      <p v-else class="text-sm">
        <i class="fa fa-arrow-up text-success"></i>
        <span class="font-weight-bold">{{ changeRate }}</span>
        {{ detail2 }} yükseliş
      </p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="chart-line" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import getPaymentReport from "../../services/paymentService"
import moment from "moment";

export default {
  name: "gradient-line-chart",
  props: {
    title: {
      type: String,
      default: "Aylık Gelirler",
    }
  },

  async mounted() {
    moment.locale('TR')
    let labels = null
    let values = null
    const today = moment();
    const startDate = moment().subtract(1, 'year').startOf('month');
    await getPaymentReport(startDate.format('YYYY-MM-DD'), today.format('YYYY-MM-DD')).then((response) => {
      labels = response.data.map((x) => moment(x.date, "YYYY-M").format("MMMM-YYYY"))
      values = response.data.map((x) => x.totalPayment)
      const changeRates = response.data.map(x => x.changeRate);
      this.changeRate = Number(changeRates[changeRates.length-1]).toFixed(2)
    });
    var ctx1 = document.getElementById("chart-line").getContext("2d");

    var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

    gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
    gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
    gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");
    new Chart(ctx1, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Toplam Gelir",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#4BB543 ",
            backgroundColor: gradientStroke1,
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            fill: true,
            data: values,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  },
  data() {
    return {
      changeRate: null,
      detail2: "bir önceki aya göre"
    };
  }
};
</script>
