<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div style="display: flex;justify-content: end;margin-bottom: 10px;">
        <argon-button color="primary" variant="gradient" active="active" @click="addNew">Yeni Ekle</argon-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <el-table :data="plan.items" row-key="id" fit="true"  style="width: 100%; min-height: 400px;" table-layout="auto"
          @current-change="handleTableRowClick">
          <el-table-column prop="name" label="Paket Adı" width="180" />
          <el-table-column prop="price" label="Fiyat (TL)" />
          <el-table-column prop="duration" label="Süre (Gün)" />
          <el-table-column prop="plannedAppointmentCount" label="Görüşme Sayısı" />
          <el-table-column label="">
            <template #default="scope">
              <el-button size="small" type="danger" @click="deletePlan(scope.$index, scope.row)">Sil</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="my-pagination" v-model:currentPage="activePage" @current-change="handlePaginationClick"
          background layout="prev, pager, next" :total="plan.totalCount" @input="fetchData" />
      </div>
    </div>

  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";

export default {
  name: "plan",
  components: {
    ArgonButton
  },
  data() {
    return {
      activePage: 1,
      plan: {
        tableTitle: "Paketler",
        column1: "Paket adı",
        column2: "Durum",
        column3: "Fiyat",
        column4: "Süre",
        items: [
        ],
        totalCount: 0,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    addNew() {
      this.$router.push("/new-plan")
    },
    handlePaginationClick() {
      this.fetchData()
    },
    handleTableRowClick(currentRow) {
      this.$router.push(currentRow.detailLink)
    },
    fetchData() {
      this.$http
        .get(`/plan?page=${this.activePage - 1}`)
        .then((response) => {
          console.log(response.data)
          const arr = []
          response.data.data.forEach((item) => {
            const plan = {
              id: item.id,
              name: item.name,
              nutritionistId: item.nutritionistId,
              plannedAppointmentCount: item.plannedAppointmentCount,
              price: item.price,
              status: item.status == 1 ? "Aktif" : "Pasif",
              type: item.status == 1 ? "success" : "info",
              duration: item.duration,

            }
            arr.push(plan)
          })
          this.plan.items = arr
          this.plan.totalCount = response.data.totalCount
          this.plan.totalPages = response.data.totalPages
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    async deletePlan(index, row) {
      this.$http
        .delete(`/plan/${row.id}`)
        .then(() => {
          this.plan.items = this.plan.items.filter(item => item.id !== row.id)
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
  }
};
</script>
