import { Auth } from 'aws-amplify';
import router from "../router";



const getRefreshToken = async () => {
    // Cognito'dan yeni bir token almak için gerekli isteği yapın
    // Örnek bir Axios isteği:
    await Auth.currentAuthenticatedUser();
    const refreshedSession = await Auth.currentSession();
    return refreshedSession.getAccessToken().getJwtToken();

    // Burada token yenileme işlemi için gerekli isteği oluşturun ve gönderin
    // Detaylarına Cognito API belgelerinden bakabilirsiniz
}

const logoutUserAndRedirect = async () => {
    try {
        // Kullanıcıyı çıkış yapmaya zorlayın (örneğin, Amplify ile)
        await Auth.signOut();

        // Kullanıcı çıkış yaptıktan sonra yönlendirilecek sayfa veya işlem
        router.push('/sign-in'); // Örnek olarak çıkış yaptıktan sonra gidecek sayfanın adresini verin
    } catch (error) {
        console.error('Logout failed', error);
        // Çıkış işlemi başarısız oldu, başka bir işlem yapabilirsiniz
    }
}

export {getRefreshToken, logoutUserAndRedirect}
