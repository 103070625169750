<template>
  <main class="height-300 position-absolute w-100 bg-success">
    <div class="container-fluid">
      <div class="card shadow-lg mt-4 col-md-12 mx-auto">
        <div class="card-body p-3">
          <div class="row gx-3">
            <div class="col-5 col-md-auto">
              <div class="avatar avatar-xxl position-relative">
                <img src="../assets/img/buseturker.jpeg" alt="profile_image" class="shadow-sm w-100 border-radius-lg" />
              </div>
            </div>
            <div class="col-7 col-md-auto my-auto">
              <div class="h-100">
                <h5>Uzm. Dyt. Buse Türker <el-icon :color="primary">
                    <CircleCheckFilled />
                  </el-icon></h5>
                <div style="margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">
                  <el-button class="mb-0 font-weight-bold text-m" text
                    style="padding-left: 0px;">
                    <el-icon style="margin-right: 5px;">
                      <Location />
                    </el-icon>
                    Benita Beslenme ve Psikolojik Danışmanlık Merkezi
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <div class="card z-index-2">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="d-flex align-items-center col-sm-3 mb-0 ">
                    <h6 class="font-weight-bold">Yorum Bilgileri</h6>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Ad</label>
                      </el-row>
                      <el-text class="mx-1" size="default">{{ commentDetail.adviseeName }}</el-text>
                    </div>
                    <div class="col-md-6">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Soyad</label>
                      </el-row>
                      <el-text class="mx-1" size="default">{{ commentDetail.adviseeSurname }}</el-text>
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                  <div class="row">
                    <div class="col-md-12">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Puan</label>
                      </el-row>
                      <el-rate class="mx-1" v-model="commentDetail.star"></el-rate>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <el-row>
                        <label for="example-text-input" class="form-control-label">Yorum</label>
                      </el-row>
                      <el-input v-model="commentDetail.text" :rows="7" type="textarea"/>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-2">
                      <el-button type="primary" size="large" @click="saveComment">Gönder</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";



const body = document.getElementsByTagName("body")[0];

export default {
  name: "comment-detail",

  data() {
    return {
      commentId: this.$route.params.id,
      commentDetail: {
        star: null
      },
      comment: null,
    };
  },
  created() {
    this.fetchCommentDetailData()
  },
  methods: {
    fetchCommentDetailData() {
      this.$http
        .get(`/comment/${this.commentId}`)
        .then((response) => {
          this.commentDetail = response.data
          this.commentDetail.creationDateTime = new Date(this.commentDetail.creationDateTime).toLocaleDateString('en-GB').replaceAll("/", ".")

        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    saveComment() {
      this.$http
        .put(`/comment`, {
          "star": this.commentDetail.star,
          "text": this.commentDetail.text,
          "commentId": this.commentId
        })
        .then(() => {
          this.$router.push("/result")
        })
    }
  },
  components: {},

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.showSidenav = false;
    this.$store.state.hideConfigButton = false;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
