import axios from 'axios';
import {getRefreshToken, logoutUserAndRedirect} from './authService'

// Axios instance oluşturun ve Vue globaline ekleyin
const axiosInstance = axios.create({
    baseURL: 'https://api-beta.buseturker.com', // İsteğinizi yapacağınız API base URL'i
});

axiosInstance.interceptors.request.use(
    (config) => {
        // JWT token'ınızı burada alın (örneğin localStorage'den)
        const email = localStorage.getItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.LastAuthUser')
        var jwtToken = localStorage.getItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.' + email + '.accessToken');
        // Eğer token varsa, Authorization header'ına ekleyin
        if (jwtToken) {
            config.headers['Authorization'] = `Bearer ${jwtToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      // 401 hatası alındığında ve yeniden deneme yapılmamışsa
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        try {
          // Yeni bir token almak için Cognito'ya istek yapın
          const email = localStorage.getItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.LastAuthUser')
          const refreshedToken = await getRefreshToken(); // Bu fonksiyonun tanımlanması gerekir
          localStorage.setItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.' + email + '.accessToken', refreshedToken);
  
          // Yenilenen token ile orijinal isteği tekrar gönderin
          originalRequest.headers['Authorization'] = `Bearer ${refreshedToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Token yenileme başarısız olursa, kullanıcıyı çıkış yapmaya zorlayabilirsiniz
          // veya başka bir işlem yapabilirsiniz.
          // Örnek:
          console.error('Token refresh failed', refreshError);
          await logoutUserAndRedirect();
          // Kullanıcıyı çıkış yapmaya yönlendirme gibi bir işlem
        }
      }
      return Promise.reject(error);
    }
  );

export default axiosInstance