<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row mt-4">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-md-4 mt-2">
                <default-info-card :classIcon="income.classIcon" :title="income.title" :desc="income.desc"
                  :price="income.price" :isHided="isCardHided" @toggle="toggleCardHided" />
              </div>
              <div class="col-md-4 mt-2">
                <default-info-card :classIcon="cost.classIcon" :title="cost.title" :desc="cost.desc" :price="cost.price"
                  :icon_bg="cost.icon_bg" :isHided="isCardHided" @toggle="toggleCardHided" />
              </div>
              <div class="col-md-4 mt-2">
                <default-info-card :classIcon="total.classIcon" :title="total.title" :desc="total.desc"
                  :price="total.price" :icon_bg="total.icon_bg" :isHided="isCardHided" @toggle="toggleCardHided" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3" style="display: flex;justify-content: end;margin-bottom: 10px;">
        <argon-button color="primary" variant="gradient" active="active" @click="addNew">Yeni Ekle</argon-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3">
        <el-table ref="tableRef" row-key="date" :data="tableData" style="width: 100%">
          <el-table-column prop="adviseeName" label="Ad Soyad" />
          <el-table-column prop="planName" label="Plan">
            <template #default="scope">
              <el-tag :type="scope.row.tag === 'Home' ? '' : 'success'" disable-transitions>{{ scope.row.planName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="Ücret">
            <template #default="scope">
              <div>{{ scope.row.price + ' ₺' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="paymentDate" label="Tarih" />
          <el-table-column label="">
            <template #default="scope">
              <el-button size="small" type="danger" @click="deletePaymentLog(scope.$index, scope.row)">Sil</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="my-pagination mb-5" v-model:currentPage="activePage" @current-change="handlePaginationClick"
          background layout="prev, pager, next" :total="totals.totalPaymentCount" @input="getPaymentLogs" />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import ArgonButton from "@/components/ArgonButton";
import moment from 'moment';


export default {
  name: "Billing",
  components: {
    DefaultInfoCard,
    ArgonButton
  },
  created() {
    this.getTotals();
    this.getPaymentLogs();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = false;
  },
  data() {
    return {
      tableFilters: [
        { text: 'Home', value: 'Home' },
        { text: 'Office', value: 'Office' },
      ],
      income: {
        classIcon: "text-white fa fa-plus",
        title: "Gelir",
        desc: "",
        price: "0"
      },
      cost: {
        classIcon: "text-white fa fa-minus",
        title: "Gider",
        desc: "",
        price: "0",
        icon_bg: "bg-gradient-danger"

      },
      total: {
        classIcon: "text-white fa fa-university",
        title: "Toplam",
        desc: "",
        price: "0",
        icon_bg: "bg-gradient-info"
      },
      isCardHided: true,
      activePage: 1,
      tableData: null,
      totals: {}
    };

  },
  methods: {
    addNew() {
      this.$router.push("/new-payment")
    },
    handlePaginationClick() {
      this.getPaymentLogs()
    },
    toggleCardHided(val) {
      this.isCardHided = val
    },
    async getTotals() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

      this.$http
        .get(`/payment-log/total?startDate=${startOfMonth}&endDate=${endOfMonth}`)
        .then((response) => {
          moment.locale('tr')
          const data = response.data
          this.income.price = (data.totalAmount || 0) + " ₺"
          this.income.desc = startOfMonth.split("-")[2] + "-" + endOfMonth.split("-")[2] + " " + moment().format('MMMM') + " tarihleri arasında elde ettiğiniz brüt gelir."
          this.cost.price = 0 + " ₺"
          this.cost.desc = startOfMonth.split("-")[2] + "-" + endOfMonth.split("-")[2] + " " + moment().format('MMMM') + " tarihleri arasında olan gider."
          this.total.price = (data.totalAmount || 0) + " ₺"
          this.total.desc = startOfMonth.split("-")[2] + "-" + endOfMonth.split("-")[2] + " " +moment().format('MMMM',) + " tarihleri arasında elde ettiğiniz net gelir."
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    async getPaymentLogs() {
      this.$http
        .get(`/payment-log?page=${this.activePage - 1}`)
        .then((response) => {
          this.tableData = response.data.data
          this.totals.totalPaymentCount = response.data.totalCount;
          this.totals.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    async deletePaymentLog(index, row) {
      this.$http
        .delete(`/payment-log/${row.id}`)
        .then(() => {
          this.tableData = this.tableData.filter(item => item.id !== row.id)
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
  }
};
</script>
