<template>
  <div class="py-4 container-fluid" style="min-height: 100vh !important;">
    <div class="row">
      <div style="display: flex;justify-content: end;margin-bottom: 10px;">
      <argon-button color="primary" variant="gradient" active="active" @click="addNew">Yeni Ekle</argon-button>
    </div>
    </div>
    <div class="row">
      <div class="col-12" style="height: 100vh;">
        <el-table :data="advisee.items" style="width: 100%; min-height: 400px;" table-layout="auto" @current-change="handleTableRowClick" v-loading="loading">
          <!-- <el-table-column prop="logo" label="Online / Yüz yüze" width="180">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <i :class="scope.row.logo"></i>
              </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="name" label="Ad Soyad" width="180" />
          <el-table-column prop="phone" label="Telefon" />
          <el-table-column prop="email" label="E-posta" />
          <el-table-column prop="packageName" label="Plan" />
          <el-table-column prop="creationDateTime" label="Kayıt Tarihi" />
        </el-table>
        <el-pagination class="my-pagination" v-model:currentPage="activePage" @current-change="handlePaginationClick"
          background layout="prev, pager, next" :total="advisee.totalCount" @input="fetchData" />
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";

export default {
  name: "advisee",
  components: {
    ArgonButton
  },
  data() {
    return {
      activePage: 1,
      loading: true,
      advisee: {
        tableTitle: "Danışanlar",
        column1: "Ad Soyad",
        column2: "Durum",
        column3: "Paket",
        column4: "Başlangıç Tarihi",
        items: [],
        totalCount: 0,
        totalPages: 0,

      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePaginationClick() {
      this.fetchData()
    },
    handleTableRowClick(currentRow) {
      this.$router.push(currentRow.detailLink)
    },
    async fetchData() {
      this.loading = true;
      this.$http
        .get(`/advisee?page=${this.activePage - 1}`)
        .then((response) => {
          console.log(response.data.data)
          const arr = []
          response.data.data.forEach((item) => {
            const creationDateTime = new Date(item.creationDateTime).toLocaleDateString('en-GB').replaceAll("/",".")
            const advisee = {
              id: item.id,
              name: item.name + " " + item.surname,
              logo: item.type == 1 ? "fas fa-globe" : "fas fa-people-arrows",
              phone: item.phone,
              email: item.email,
              creationDateTime: creationDateTime,
              detailLink: "/advisee/" + item.id
            }
            arr.push(advisee)
          })
          this.advisee.items = arr
          this.advisee.totalCount = response.data.totalCount
          this.advisee.totalPages = response.data.totalPages
          console.log(this.advisee)
          this.loading = false;

        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    addNew() {
      this.$router.push("/new-advisee")
    },
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = false;
  }
};
</script>
