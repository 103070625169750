import { Auth } from "aws-amplify";


export default async (to, from, next)=>{
    if ( to.name !== 'Signin' && to.meta.isAuthenticated ){
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false
            });
            console.log('ozan',user)
            next();
        } catch {
            next({
                path: '/signin',
                replace: true
            })
        }

    /*next({
    path: '/signin',
    replace: true})*/

    } else {
    next(); }
}