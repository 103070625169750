<template>
  <div class="card pb-4" v-if="cardMode == 'PackageList'">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Paket Geçmişi</h6>
        </div>
        <div class="col-6 text-end">
          <argon-button color="success" size="sm" variant="outline" @click="addNewPackage">Yeni Paket Ekle</argon-button>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li v-for="item in items" :key="item.id"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">{{ item.name }}</h6>
          </div>
          <div class="d-flex flex-column align-items-center text-sm">
            <span class="text-s">{{ item.startDate }} - {{ item.endDate }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- <Transition name="bounce"> -->
  <div class="card pb-4 animate__animated animate__bounce" v-if="cardMode == 'PackageSave'">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-sm-3 text-start">
          <argon-button color="dark" size="sm" variant="outline" @click="back">Geri</argon-button>
        </div>
        <div class="col-sm-6 d-flex align-items-center">
          <h6 class="mb-0">Yeni Paket</h6>
        </div>
        <div class="col-sm-3 text-end">
          <argon-button color="success" size="sm" variant="outline" @click="add">Ekle</argon-button>
        </div>

      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <div class="row">
        <div class="col-md-12">
          <label for="example-text-input" class="form-control-label">Başlangıç tarihi</label>
          <el-date-picker id="planStartDate" style="width: 100%;" v-model="this.planStartDate" type="date" size="large"
            format="DD.MM.YYYY" value-format="DD.MM.YYYY" />
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <label for="example-text-input" class="form-control-label">Paket</label>
          <el-select style="width: 100%;" v-model="this.selectedPlan" value-key="id" size="large">
            <el-option id="planSelect" v-for="item in plan.items" :key="item.id" :label="item.name" :value="item" />
          </el-select>

        </div>
      </div>
    </div>
  </div>
  <!-- </Transition> -->
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";


export default {
  name: "invoice-card",
  data() {
    return {
      cardMode: "PackageList",
      adviseeId: this.$route.params.id,
      activePage: 1,
      planStartDate: null,
      plan: {},
      selectedPlan: null,
      items: []
    };
  },
  created() {
    this.fetchPackageHistoryData();
  },
  components: {
    ArgonButton,

  },
  methods: {
    addNewPackage: function () {
      this.fetchData().then(() => this.cardMode = "PackageSave")

    },
    back: function () {
      this.cardMode = "PackageList"
    }
    ,
    add: function () {
      this.savePlanLog()
      this.fetchPackageHistoryData()
      this.cardMode = "PackageList"
    },
    fetchData() {
      return this.$http
        .get(`/plan?page=${this.activePage - 1}`)
        .then((response) => {
          console.log(response.data)
          const arr = []
          response.data.data.forEach((item) => {
            const plan = {
              id: item.id,
              name: item.name,
              nutritionistId: item.nutritionistId,
              plannedAppointmentCount: item.plannedAppointmentCount,
              price: item.price,
              status: item.status == 1 ? "Aktif" : "Pasif",
              type: item.status == 1 ? "success" : "info",
              duration: item.duration,

            }
            arr.push(plan)
          })
          this.plan.items = arr
          this.plan.totalCount = response.data.totalCount
          this.plan.totalPages = response.data.totalPages
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    savePlanLog() {
      return this.$http
        .post(`/advisee/${this.adviseeId}/plan-log`,
          {
            "planId": this.selectedPlan.id,
            "startDate": this.planStartDate,
          })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    fetchPackageHistoryData() {
      this.$http
        .get(`/advisee/${this.adviseeId}/plan-log`)
        .then((response) => {
          console.log(response.data)
          const packageHistories = []
          response.data.forEach((item) => {
            const packageHistory = {
              name: item.planName,
              startDate: item.startDate,
              endDate: item.endDate
            }
            packageHistories.push(packageHistory)
          })
          this.items = packageHistories
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },

  }
};
</script>
