import httpService from './httpService';

const searchAdviseeByName = (searchKey) => {
  return httpService
    .get(`/advisee?searchKey=${searchKey}`)
    .then((response) => {
       const adviseeApiResult = response.data.data.map((item) => {
        return {
          id: item.id,
          name: item.name + " " + item.surname,
          logo: item.type == 1 ? "fas fa-globe" : "fas fa-people-arrows",
          phone: item.phone,
          status: item.status == 1 ? 'Aktif' : 'Pasif',
          package: item.planName,
          startDate: item.planStartDate,
          detailLink: "/advisee/" + item.id
        }
      })
      return adviseeApiResult
    })
    .catch((error) => {
      console.error('Hata:', error);
    });
}

export { searchAdviseeByName, }