<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-10">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Yeni Danışan Ekle</p>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Kişisel Bilgiler</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Ad</label>
                  <el-input v-model="name" size="large" />
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Soyad</label>
                  <el-input v-model="surname" size="large" />
                </div>
              </div>
              <div class="row" style="margin-top: 1rem;">
                <div class="col-md-6">
                  <label for="genderSelect" class="form-control-label">Cinsiyet</label>
                  <el-select style="width: 100%;" v-model="selectedGenderValue" size="large" default-first-option="true">
                    <el-option id="genderSelect" v-for="item in options" :key="item.value" :label="item.label"
                      :value="item.value" />
                  </el-select>
                </div>
                <div class="col-md-6">
                  <label for="birtdaySelect" class="form-control-label">Doğum tarihi</label>
                  <el-date-picker id="birtdaySelect" style="width: 100%;" v-model="birthday" type="date" size="large"
                    format="DD.MM.YYYY" value-format="DD.MM.YYYY" />
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">İletişim Bilgileri</p>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="citySelect" class="form-control-label">Telefon</label>
                    <el-input v-model="phone" size="large">
                      <template #prepend>+90</template>
                    </el-input>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">E-mail</label>
                  <el-input v-model="email" size="large" />
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="citySelect" class="form-control-label">İl</label>
                    <el-select style="width: 100%;" v-model="selectedCity" @change="cityChanged" size="large">
                      <el-option id="citySelect" v-for="item in cities" :key="item.id" :label="item.name"
                        :value="item.id" />
                    </el-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="countySelect" class="form-control-label">İlçe</label>
                    <el-select style="width: 100%;" :disabled="countySelectState" v-model="selectedCounty" size="large">
                      <el-option id="countySelect" v-for="item in counties" :key="item.id" :label="item.name"
                        :value="item.id" />
                    </el-select>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Danışmanlık Bilgileri</p>
              <div class="row">
                <!-- <div class="col-md-8">
                  <div class="form-group">
                    <label for="planSelect" class="form-control-label">Plan</label>
                    <el-select style="width: 100%;" v-model="selectedPlanValue" size="large">
                      <el-option id="planSelect" v-for="item in planOptions.items" :key="item.id" :label="item.name"
                        :value="item.name" />
                    </el-select>
                  </div>
                </div> -->
                <!-- <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label">Ölçüm</label>
                  <el-input v-model="surname" size="large" />
                </div> -->
              </div>
              <div class="row">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Notlar</label>
                  <el-input v-model="note" :autosize="{ minRows: 3, maxRows: 6 }" type="textarea" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <argon-button color="success" size="sm" class="ms-auto" @click="saveAdvisee">Kaydet</argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "new-advisee",
  data() {
    return {
      name: "",
      surname: "",
      birthday: "",
      phone: "",
      email: "",
      note: "",
      showMenu: false,
      selectedGenderValue: null,
      options: [
        {
          value: 1,
          label: 'Erkek',
        },
        {
          value: 0,
          label: 'Kadın',
        }],
      cities: [],
      selectedCity: "",
      selectedCounty: "",
      counties: [],
      countySelectState: true,
      planOptions: {
        items: [],
        totalCount: 0,
        totalPages: 0
      },
      selectedPlanValue: ""
    };
  },
  components: { ArgonButton },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.cities = this.fetchCityData()
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
  methods: {
    fetchCityData() {
      this.$http
        .get("/city")
        .then((response) => {
          console.log(response.data)
          this.cities = response.data
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    fetchCountyData(cityId) {
      this.$http
        .get(`/county/${cityId}`)
        .then((response) => {
          console.log(response.data)
          this.counties = response.data
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    cityChanged(selectedCity) {
      this.fetchCountyData(selectedCity)
      this.countySelectState = false
    },
    saveAdvisee() {
      this.$http
        .post(`/advisee`, {
          "email": this.email,
          "name": this.name,
          "surname": this.surname,
          "gender": this.selectedGenderValue,
          "birthday": this.birthday,
          "phone": this.phone,
          "cityId": this.selectedCity,
          "countyId": this.selectedCounty,
          "note": this.note,
        })
        .then(() => {
          this.$router.push("/advisee")
        })
    }
  }
};
</script>
