<template>
  <div class="card pb-4" v-if="cardMode == 'HistoryList'">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Ödemeler</h6>
        </div>
        <div class="col-6 text-end">
          <argon-button color="success" size="sm" variant="outline" @click="addPayment">Yeni Ödeme</argon-button>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li v-for="item in items" :key="item.id"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">{{ item.paymentDate }}</h6>
          </div>
          <div class="d-flex align-items-center text-sm">
            {{ item.amount }} TL
            <!--             <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i> PDF
            </button> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card pb-4" v-if="cardMode == 'PaymentSave'">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-sm-3 text-start">
          <argon-button color="dark" size="sm" variant="outline" @click="back">Geri</argon-button>
        </div>
        <div class="col-sm-6 d-flex align-items-center">
          <h6 class="mb-0">Yeni Ödeme</h6>
        </div>
        <div class="col-sm-3 text-end">
          <argon-button color="success" size="sm" variant="outline" @click="savePayment">Kaydet</argon-button>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <div class="row">
        <div class="col-md-12">
          <label for="example-text-input" class="form-control-label">Ödeme tarihi</label>
          <el-date-picker id="paymentDate" style="width: 100%;" v-model="this.paymentDate" type="date" size="large"
            format="DD.MM.YYYY" value-format="DD.MM.YYYY" />
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <label for="example-text-input" class="form-control-label">Tutar</label>
          <el-input v-model="this.amount" size="large">
            <template #append>
              <i class="fas fa-lira-sign"></i>
            </template>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";


export default {
  name: "invoice-card",
  data() {
    return {
      cardMode: "HistoryList",
      adviseeId: this.$route.params.id,
      items: [],
      paymentDate: null,
      amount: null,

    };
  },
  created() {
    this.fetchPaymentHistoryData();
  },
  methods: {
    addPayment: function () {
      this.cardMode = "PaymentSave"
    },
    savePayment: function () {
      this.savePaymentLog().then(() => {
        this.fetchPaymentHistoryData();
        this.cardMode = "HistoryList";
        this.paymentDate=null
        this.amount= null
      });

    },
    back: function () {
      this.cardMode = "HistoryList"
    },
    fetchPaymentHistoryData() {
      this.$http
        .get(`/advisee/${this.adviseeId}/payment-log`)
        .then((response) => {
          console.log(response.data)
          const paymentHistories = []
          response.data.forEach((item) => {
            const paymentHistory = {
              paymentDate: item.paymentDate,
              amount: item.price
            }
            paymentHistories.push(paymentHistory)
          })
          this.items = paymentHistories

        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    savePaymentLog() {
      return this.$http
        .post(`/advisee/${this.adviseeId}/payment-log`,
          {
            "price": this.amount,
            "paymentDate": this.paymentDate,
          })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
  },
  components: {
    ArgonButton
  },
};
</script>
