<template>
  <div class="card h-100 mb-4" v-if="cardMode == 'MeasurementList'">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-6">
          <h6 class="mb-0">Ölçümler</h6>
        </div>
        <div class="col-6 text-end">
          <argon-button color="success" size="sm" variant="outline" @click="newMeasurement">Yeni Ölçüm</argon-button>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li v-for="item in items" :key="item.id" class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex align-items-center">
            <argon-button color="info" variant="outline" size="sm"
              class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center">
              <i v-if="item.increased==-1" class="fas fa-arrow-up" aria-hidden="true"></i>
              <i v-else-if="item.increased==1" class="fas fa-arrow-down" aria-hidden="true"></i>
              <i v-else class="fas fa-arrows-alt-h" aria-hidden="true"></i>
            </argon-button>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-dark text-sm">{{ item.measurementDate }}</h6>
            </div>
          </div>
          <div class="d-flex align-items-center text-danger text-dark text-sm font-weight-bold">{{ item.weightDifference }} Kg</div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card h-100 mb-4" v-if="cardMode == 'MeasurementSave'">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-sm-3 text-start">
          <argon-button color="dark" size="sm" variant="outline" @click="back">Geri</argon-button>
        </div>
        <div class="col-sm-6">
          <h6 class="mb-0">Yeni Ölçüm</h6>
        </div>
        <div class="col-sm-3 text-end">
          <argon-button color="success" size="sm" variant="outline" @click="saveMeasurement">Kaydet</argon-button>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <div class="row">
        <div class="col-md-12">
          <label for="example-text-input" class="form-control-label">Ölçüm tarihi</label>
          <el-date-picker id="measurementDate" style="width: 100%;" v-model="this.measurementDate" type="date" size="large"
            format="DD.MM.YYYY" value-format="DD.MM.YYYY" />
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="form-control-label">Ölçüm</label>
            <el-input v-model="this.measurement" size="large">
            <template #append>
              <span> Kg </span>
            </template>
          </el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";


export default {
  name: "transaction-card",
  components: {
    ArgonButton,
  },
  data() {
    return {
      cardMode: "MeasurementList",
      adviseeId: this.$route.params.id,
      measurementDate: null,
      measurement: null,
      items: []
    };
  },
  created() {
    this.fetchMeasurementHistoryData();
  },
  methods: {
    newMeasurement: function () {
      this.cardMode = "MeasurementSave"
    },
    saveMeasurement: function () {
      this.saveMeasurementLog().then(() => {
        this.fetchMeasurementHistoryData()
        this.cardMode = "MeasurementList"
        this.measurementDate= null
        this.measurement= null
      });
      
      this.cardMode = "MeasurementList"
    },
    back: function () {
      this.cardMode = "MeasurementList"
    },
    fetchMeasurementHistoryData() {
      this.$http
        .get(`/advisee/${this.adviseeId}/measurement-log`)
        .then((response) => {
          console.log(response.data)
          const measurementHistories = []
          response.data.forEach((item) => {
            const measurementHistory = {
              measurementDate: item.measurementDate,
              weight: item.weight,
              increased: item.increased,
              weightDifference: item.weightDifference
            }
            measurementHistories.push(measurementHistory)
          })
          this.items = measurementHistories

        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    saveMeasurementLog() {
      return this.$http
        .post(`/advisee/${this.adviseeId}/measurement-log`,
          {
            "weight": this.measurement,
            "measurementDate": this.measurementDate,
          })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
  },
};
</script>
