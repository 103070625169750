<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Giriş Yap</h4>
                  <p class="mb-0">Giriş yapabilmek için email ve şifrenizi giriniz.</p>
                </div>
                <div class="card-body">
                  <div> 
                    <argon-alert v-if="error" color="danger"> {{ error }} </argon-alert>
                   </div>
                    <div class="mb-3">
                      <argon-input type="email" placeholder="Email" name="email" size="lg" @input="updateEmail" />
                    </div>
                    <div class="mb-3">
                      <argon-input type="password" placeholder="Şifre" name="password" size="lg" @input="updatePassword" />
                    </div>
                      <div class="col-sm">
                      <argon-switch id="rememberMe" @click="rememberMe" >Beni hatırla</argon-switch>
                      <a
                      href="/forgot-password"
                      class="text-success text-gradient font-weight-bold col-sm-3"
                      >Şifremi unuttum?</a>
                      </div>

                    <div class="col-sm">
                   
                </div>

                    <div class="text-center" >
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        @click="signIn"
                      >Giriş Yap</argon-button>
                    </div>

                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                   Hesabınız yok mu?
                    <a
                      href="/signup"
                      class="text-success text-gradient font-weight-bold"
                    >Üye Olun</a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
                background-size: cover;"
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Attention is the new currency"</h4>
                <p
                  class="text-white position-relative"
                >The more effortless the writing looks, the more effort the writer actually put into the process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert";
import { Auth } from 'aws-amplify';


const body = document.getElementsByTagName("body")[0];

  
export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
      isRemember: false
    }
  },
  name: "signin",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
    ArgonAlert,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
    if (this.$store.state.email) {
      this.email = this.$store.state.email
    }
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    updateEmail(event) {
      this.email = event.target.value;
    },
    updatePassword(event) {
      this.password = event.target.value;
    },
    rememberMe() {
      this.isRemember = !this.isRemember
    },
    async signIn(event) {
      event.preventDefault()
      if (!this.email.includes('@')) {
        this.error = 'Email adresi geçersiz.'
        return
      }
      try {
        await Auth.signIn(this.email, this.password)
        this.$router.push("/")
      } catch (error) {
        if (error.name === "NotAuthorizedException") {
          this.error = 'Kullanıcı adı veya şifre yanlış.'
        }
      }
      if (this.isRemember) {
        this.$store.state.email=event.target.value
      }
    }
  }
};
</script>
