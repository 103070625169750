<template>
  <main>
    <div class="container-fluid">
      <div class="page-header min-height-250" style="
          background-image: url('https://images.unsplash.com/photo-1577235857317-9aa8f577a3a9?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
          margin-right: -24px;
          margin-left: -34%;
        ">
        <span class="mask bg-gradient-warning opacity-4"></span>
      </div>
      <div class="card shadow-lg mt-n8 col-md-8 mx-auto">
        <div class="card-body p-2">
          <div class="row gx-4">
            <div class="col-5 col-md-auto">
              <div class="avatar avatar-xxl position-relative">
                <img src="../assets/img/buseturker.jpeg" alt="profile_image" class="shadow-sm w-100 border-radius-lg" />
              </div>
            </div>
            <div class="col col-md-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">Uzm. Dyt. Buse Türker <el-icon :color="primary">
                    <CircleCheckFilled />
                  </el-icon></h5>
                  <div @click="handlePhoneClick" style="margin-top: 10px;">
                  <a :href="'tel:05364561330'" class="mb-0 font-weight-bold">
                    <el-icon style="margin-right: 5px;">
                      <Phone />
                    </el-icon>
                    <span class="text-m" style="white-space: normal;">Hemen Ara (0536 456 13 30)</span>
                  </a>
                </div>
                <div style="margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">
                  <el-button class="mb-0 font-weight-bold text-m" @click="scrollToAddress" text
                    style="padding-left: 0px;">
                    <el-icon style="margin-right: 5px;">
                      <Location />
                    </el-icon>
                    Benita Beslenme ve Psikolojik Danışmanlık Merkezi
                  </el-button>
                </div>
                <div class="row" style="margin-top: 5px;">
                  <div class="col-md-5">
                  <el-button class="mb-0 font-weight-bold text-m" @click="clickBeslenmeArsivim" text
                    style="padding-left: 0px; white-space: normal;">
                    <el-icon style="margin-right: 5px;">
                      <i class="fab fa-instagram"></i>
                    </el-icon>
                    beslenmearsivim
                  </el-button>
                </div>
                <div class="col-md-5">
                  <el-button class="mb-0 font-weight-bold text-m" @click="clickDoktorTakvimi" text
                    style="padding-left: 0px; white-space: normal;">
                    <el-icon style="margin-right: 5px;">
                      <i class="fas fa-star-of-life"></i>
                    </el-icon>
                    Doktor Takvimi
                  </el-button>
                </div>
                </div>
                <div>
                  <el-button class="mb-0 font-weight-bold text-m" @click="scrollToComment" text
                    style="padding-left: 0px; white-space: normal;">
                    <el-icon style="margin-right: 5px;">
                      <ChatLineSquare />
                    </el-icon>
                    Görüşler (43)
                  </el-button>
                </div>

              </div>
            </div>
            <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0" style="display: none;">
              <div class="nav-wrapper position-relative end-0">
                <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
                  <li class="nav-item">
                    <el-button type="success" round class="col-auto float-md-end" style="float: right;">
                      <el-icon style="margin-right: 10px;">
                        <Calendar />
                      </el-icon> Randevu Oluştur
                    </el-button>

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid col-md-8 mx-auto">
      <div>
        <div>
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0" style="font-weight: bold;">Özgeçmiş</p>
              </div>
            </div>
            <div class="card-body">
              <p class="text-m">Hakkında</p>
              <div class="row">
                <div class="col-md-auto" style="color: #333;">
                  <span>
                    1995 İzmir doğumludur. İlk ve orta öğrenimini İzmir’de tamamlamıştır.
                    2017 yılında Medipol Üniversitesi Beslenme ve Diyetetik Lisans bölümünden mezun olmuştur.
                    Eğitimi süresince eğitim araştırma hastanelerinde ve toplum sağlığı merkezlerinde staj yaparak deneyim
                    kazanmıştır.
                    2018-2022 yılları arasında Ege Üniversitesi Sağlık Bilimleri Enstitüsü Kronik Hastalıklar
                    Anabilim Dalı- Diyabet Destek Yüksek Lisans programına kabul almış ve uzmanlığını tamamlamıştır.
                    Mezuniyet sonrası çeşitli özel diyet merkezlerinde, catering firmalarında diyetisyen olarak görev
                    almıştır.
                    Fonksiyonel Tıp Akademisi tarafından verilen Fonksiyonel Tıp Temel ve İleri Seviye Diyetisyenlik
                    eğitimini başarıyla tamamlamıştır.
                    Kariyerine kurucusu olduğu BENİTA Beslenme ve Psikolojik Danışmanlık Merkezinde devam etmektedir.
                  </span>
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-m">İlgi Alanları</p>
              <div class="row">
                <div class="col-md-12" style="color: #333;">
                  <ul>
                    <li>
                      Şişmanlık (Obezite)
                    </li>
                    <li>
                      Diyabet (Şeker Hastalığı)
                    </li>
                    <li>
                      Hiperlipidemi
                    </li>
                    <li>
                      Tip 1 Diyabet
                    </li>
                    <li>
                      Gut Hastalığı ve Beslenme
                    </li>
                    <li>
                      İnsülin Direnci ve Metabolik Sendrom
                    </li>
                    <li>
                      Tip 2 Diyabet
                    </li>
                    <li>
                      Anoreksiya ve Bulimia Hastalarında Beslenme
                    </li>
                    <li>
                      Troid Hastalıklarında Beslenme
                    </li>
                    <li>
                      Kilo Alma Diyetleri
                    </li>
                    <li>
                      Çocuk ve Adölesanlarda Beslenme
                    </li>
                  </ul>
                  <span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-0 container-fluid col-md-8 mx-auto">
      <div>
        <div>
          <div class="card" ref="addressCard">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0" style="font-weight: bold;">Adres</p>
              </div>
            </div>
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-auto col-3">
                  <el-icon size="45px">
                    <Location />
                  </el-icon>
                </div>
                <div class="col-md-5 col-9" style="color: #333;">
                  <span>Kasımpaşa Mah. 1046 Sk. No:5 Kat:3 D:32 <br> Dr. Necmi Özgüven İş Merkezi<br> Kasımpaşa, 35660
                    Menemen/İzmir, Türkiye</span>
                </div>
                <div class="col-md-6 col-12 mt-3 mt-md-0">
                  <GMapMap :options="options" :center="center" :zoom="18" style="height: 200px; width: 100%;">
                    <GMapCluster>
                      <GMapMarker :position="center" :clickable="false" :draggable="false">
                      </GMapMarker>
                    </GMapCluster>
                  </GMapMap>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="py-4 container-fluid col-md-8 mx-auto">
      <div>
        <div>
          <div class="card" ref="commentCard">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0" style="font-weight: bold;">Yorumlar</p>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <comment v-for="(comment, index) in comments.slice(0, lastCommentIndex)" :key="index"
                  :author="comment.author" :text="comment.text" :date="comment.date"></comment>
                <el-button v-if="lastCommentIndex < 43" @click="loadMoreComment">Daha fazla göster</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Comment from "./components/Comment.vue";
import { v4 as uuidv4 } from 'uuid';



const body = document.getElementsByTagName("body")[0];

export default {
  name: "profile",
  data() {
    return {
      showMenu: false,
      center: { lat: 38.610353212554855, lng: 27.072879601009912 },
      options: {
        mapId: "52d3d240e01cb416",
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
      },
      lastCommentIndex: 10,
      commentIncrementValue: 10,
      comments: [
        {
          "author": "k.....e",
          "text": "Harika bir insan,sevecen,ılımlı,pozitif,çok ilgili ve her konuda yardımcı.Daha ilk randevumuzda diyetimizi birlikte oluşturduk.ve sonraki günler sürekli diyetimin nasıl gittiği ilede oldukça ilgiliydi.Çok teşekkür ederim.İyiki varsınız Buse hanım.",
          "date": "19 Kasım 2023"
        },
        {
          "author": "a.....",
          "text": "Çok sıcakkanlı, doğru beslenmek için iyi bir yol arkadaşı İyi ki yollarımız kesişti. Başarılı bir süreç yürütüyoruz",
          "date": "18 Kasım 2023"
        },
        {
          "author": "mu...n",
          "text": "Buse hanım, 1.5 ay gibi kısa bir sürede 12 kilo vermeme yardımcı oldu. Verdiği diyet listesini birebir uyguladım. Listedekiler, içemeğeceğim bitki sularından ve tatsız tutsuz yiyeceklerden oluşmuyordu. Her seferinde haftalık listemizi çarşıda, pazarda bulabileceğimiz meyve ve sebzelerden oluşturduk. Hiç aç kalmadım. Daha önce kendi uyguladığım diyetlerle boşuna aç kalmışım. Buse hanıma çok teşekkür ediyorum. Böyle naif, kibar, güler yüzlü uzman bir diyetisyenle kendinize bir şans verin. Hiç pişman olmayacaksınız",
          "date": "24 Ekim 2023"
        },
        {
          "author": "m....s",
          "text": "Buse hanım ile tanıştığım için çok mutluyum çünküv yaşam enerjimi, beslenme kalitemi yenilememe yardımcı oldu.",
          "date": "19 Ekim 2023"
        },
        {
          "author": "bü...z",
          "text": "Buse Hanım oldukça ilgili aklıma takılan en ufak şeyi soruyorum. Kilo verme sürecimde çokça tok kalarak kilo veriyorum.",
          "date": "18 Ekim 2023"
        }, {
          "author": "m....ö",
          "text": "Buse hanımla sürecimizde gerek yaklaşımı gerekse sürece verdiği destek konusunda çok memnun olduğum bir dönem geçirdik. Yaklaşık 5 kg verdim ve bu süreçte bana her yönlü destek olarak problemlerimi tek tek çözdü . En önemlisi de 7/24 destek olması samimiyeti ve güleryüzlülüğü . Verdiği listeler hem kolay yapılabilir hem de ulaşılabilir ürünler oldu. Alternatif listeler ve çalışmalar ile beni hep destekledi",
          "date": "18 Ekim 2023"
        },
        {
          "author": "öz...i",
          "text": "Gluten hassasiyetime verilen listeler ile harika sonuçlar almaya devam edıyorum",
          "date": "14 Ekim 2023"
        },
        {
          "author": "h...",
          "text": "Uzmanıma İnternet aracılığıyla tanıştım, iyi ki tanışmışım. Tiroidim çalışmadığı için bir tedavi sürecimiz olduğu için başvurmuştum. Uzmanımla sürecimiz çok güzel ilerliyor, inşallah biraz daha yollarımız aynı olacak. Uzmanımın güzelce beni dinlemesi ve sorunuma odaklanarak sorduğum sorulara anında cevapları çok seviyorum. Çok güzel listeler oluşturarak güzel bir yola ilerliyoruz birlikte, iyi ki tanımışım Buse Hanım'ı. Çevreme tabii ki tavsiye ederim ve ediyorum da, doğru listeyle doğru adımlarla güzel bir süreç ilerleyebilirler. Sadece Buse Hocamı üzmeden, verdikleri listeye uyarak kaçamak yapmayarak ilerleyebilirler",
          "date": "27 Ağustos 2023"
        },
        {
          "author": "a.....",
          "text": "Uzun zamandır olamadığım kadar sağlıklı hissediyorum şişkinliğimin çoğu geçti ve Buse hanım sayesinde doğru adımlarla ilerlediğimizin farkındayım beslenme programlarına alışmam gerçekten çok kolay oldu düzenli olarak kilo verebiliyorum bu da motivasyonumu inanılmaz arttırıyor Buse hanıma gerçekten çok teşekkür ederim",
          "date": "25 Ağustos 2023"
        },
        {
          "author": "d...",
          "text": "Arkadaşımın önerisiyle Buse Hanım ile tanıştık ve gerçekten iyi ki tanışmışız. Buse Hanım'ın enerjisi ve yaklaşımı çok güzel, diyet sürecimiz oldukça keyifli ilerliyor. Alanında uzman bir diyetisyen olarak her danışanının yaşam tarzına ve yeme alışkanlıklarına uygun bir beslenme planı hazırlıyor. Bu sayede ben de 12 kg kadar kilo verdim. Çevremdeki insanlar değişimimi görünce hangi diyetisyenle çalıştığımı merak ediyorlar ve ben de hemen doğru adrese yönlendiriyorum. Buse Hanım'a teşekkür etmek istedim. Onun rehberliğinde sağlıklı beslenme alışkanlıkları kazanarak kilo veriyorum ve aç kalmadan başarılı sonuçlar alıyorum.",
          "date": "4 Ağustos 2023"
        },
        {
          "author": "s....b",
          "text": "Eşimle birlikte kendisinden kilo vermek üzerine destek almaya başladık, gayet memnunum, hala diyet sürecime devam ediyorum. Bu süreçte kendisinin güleryüzlü olması çok iyi, danışanını karşılaması çok güzel, listelere uyulduğu zaman da kilo verdiğimi hissediyorum, seans almaya başlayalı iki ayı geçti, eşimin seansları sonlandı, seans sürecinde 20 kg verdi, ben de şu ana kadar 15 -16 kg verdim. 10-15 gün sonra memlekete gideceğimden dolayı seansları bırakmayı düşünüyorum, daha sonra duruma göre tekrar devam edebilirim.",
          "date": "1 Ağustos 2023"
        },
        {
          "author": "e....k",
          "text": "Buse Hanıma eşim ile birlikte diyet ve sağlıklı beslenme için başvurduk. bu süreçte hem çok sağlıklı menüler ile tanıştık ,hem de kilo verdik kilo vermek ve mutlu olmak istiyorsanız hiç vakit kaybetmeden Benita beslenme ve psikolojiye gitmelisiniz",
          "date": "15 Haziran 2023"
        },
        {
          "author": "s.....",
          "text": "Alanında uzman , menüleri zengin , sempatik , yeniliklere açık , kısa zamanda istikrarla kilo vermeme yardımcı olduğu İçin kendisine çok teşekkür ediyorum .",
          "date": "13 Haziran 2023"
        },
        {
          "author": "gö...",
          "text": "3 hafta oldu başlayalı kan sonuçlarım için başlamıştık 3. Hafta sonunda 9.5 kg verdim. Aç kalmadan beslenme yetersiz kalmadan sanki hiç diyet yapmıyorum harika bir duygu ilk kez diyetisyen hikayesi olan birisi olarak buse hanım tavsiye ederim",
          "date": "7 Haziran 2023"
        },
        {
          "author": "h...",
          "text": "Buse Hanım'a internette araştırmalarım sonucu ulaştım. Tip1 diyabetim, hipertansiyonum ve hashimoto hipotiroidim var. Diyabetimde süreci yönetemediğim ve fazla kilolarımdan kurtulmak istediğim için başvurdum. Kendisi için uzun yollar gidip geldim. Çalışmaya başlayınca sürecimiz maalesef ki sonlandı. Fakat benimle bu süreçte çok iyi ilgilendi. Çok güler yüzlü, katı kuralları olmayan çözüm odaklı ve en önemlisi sürdürülebilir beslenmeyi çok iyi aşılayan birisi. Kötü seyreden, 8.5'dan inmeyen hba1cm şuan 7 geldi. Tiroidlerim düzene girdi. Kesinlikle herkese tavsiye ediyorum. İyi ki denk gelmişiz ve yollarımız kesişmiş. Kendisine tekrardan çok teşekkür ederim.",
          "date": "23 Mayıs 2023"
        },
        {
          "author": "p....",
          "text": "Buse Hanım'dan çok memnunum. Harika bir şekilde programım devam ediyor. Kendisi güleryüzlü, şeker, çok ilgili, hep arayıp soran, programımı takip eden bir uzman. Buse Hanım'la kilo vermek amacıyla görüşme sağladım. 2. ayımı tamamladım 83 kiloydum şu anda 71 kiloyum. Diyet listelerimi uygularken hiç zorluk yaşamıyorum tam uydu. Listelerim maddi durumuna uydu, harika oluyor. Şekerim, astımım vardı şu anda onlarla ilgili sorun yaşamıyorum.",
          "date": "22 Mayıs 2023"
        },
        {
          "author": "f.....",
          "text": "Buse Hanım ilgili bir uzman. Diyet konusunda birebir görüşmelerinden ve sonuç konusunda çok memnun kaldım. Kendisiyle kilo vermek amacıyla görüşme sağladım. 2 ay danışmanlık hizmeti aldım arzu ettiğimiz hedefime ulaştığım için devam etmiyorum. Diyet listelerimi bana özel hazırlandı sevdiğim yapabileceğim sevmediğim şeyleri filtreleyerek çalıştık. Haricinde yeni daha önce denemediğim tarifler oldu. Her zaman sevdiğim yapabileceğim şeylere göre düzenledik. Tamamen özelleştirmiş kişiye özel liste oldu. Kendisinden aldığım danışmanlık hizmetinden memnun kaldım.",
          "date": "21 Mayıs 2023"
        },
        {
          "author": "l.....",
          "text": "Buse Hanım'dan çok memnunum. Kendisi çok iyi, sıcakkanlı, samimi bir diyetisyen. arkadaşımın tavsiyesi üzerine kilo vermek amacıyla tanıştım. Diyet programı uyguluyoruz çok güzel ilerliyor ve çok memnunum. Emziren çocuğum var ve çok güzel kilo verdim hem de sütüm çoğaldı. Buse Hanım'la diyet listelerimizi birlikte sevip sevmediğim yiyeceklere göre hazırladık ve gayet güzel ilerledik. Kendisiyle 18 Şubat'ta görüşme sağlamaya başlamıştım 8 kilo verdim ve sürecime devam ediyorum.",
          "date": "16 Nisan 2023"
        },
        {
          "author": "f.....",
          "text": "Buse Hanım'dan çok memnunum. Kendisi güler yüzlü, işinde gayet uzman bir diyetisyen. Buse Hanım'ı tavsiye üzerine kilo vermek amacıyla görüşme sağladım. 2 ay danışmanlık hizmeti aldım ve 8 kilo verdim. Diyet listelerimi uygularken zorluk yaşamadım, benim için özel hazırlanmıştı kendime ait menüydü.",
          "date": "15 Nisan 2023"
        },
        {
          "author": "g.....",
          "text": "Tanıdığım vesilesi ile ulaştım kilo problemim vardı 2 ayı aşkın bir süredir kendisiyle çalışıyordum kendisi mükemmel bir diyetisyen listeleri harika mükemmel bir diyetisyen ve aynı zaman mükemmel bir ablada çok cana yakın gerçekten herkese tavsiye ediyorum",
          "date": "15 Mart 2023"
        },
        {
          "author": "f.....",
          "text": "Buse hanıma zayıflamak için gittim, yaşam tempoma uygun besleneme alışkanlığı üzerine çalıştık. Çok güzel ilgilendi ve takip etti. Beni güzel yönlendirdi.Açıklayıcı ve örnekler ile konuştu. Özellikle hangi besin gruplarını kendi içinde değiştirebileceğimi, nasıl dengeleyeceğimi güzel bir şekilde izah etti. Çok memnun kaldım. Çevremdeki herkese de tavsiye ettim",
          "date": "15 Mart 2023"
        },
        {
          "author": "g....d",
          "text": "Ben kendisinden diyet üzerine seans almıştım, pandemi döneminde görüşmelerimiz olmuştu, akabinde seanslarıma hala devam ediyorum, kendisi çok pozitif biri, süreçte her konuda danışanına yardımcı oluyor, kendisi için negatif hiç bir şey söyleyemem, pandemi döneminde seans aldığım süreçte 11 kiloya yakın kilo vermiştim, daha sonra ara verdiğim dönemde bir 2-3 kilo aldım, şuan da bu direnci kırmak, kilomu kontrol etmek amaçlı görüşüyorum.",
          "date": "31 Ocak 2023"
        },
        {
          "author": "ö....p",
          "text": "Çok sıcakkanlı ve samimi biri enerjisi muhteşem çok memnunum. Yavaş bir kilo kaybım olmasına rağmen enerjimi yüksek tutmamda yardımcı oluyor.",
          "date": "30 Ocak 2023"
        },
        {
          "author": "ö....a",
          "text": "Kendisinden son derece hoşnut kaldım, kilo vermek üzerine bir dönem görüştüm, kilo da verdim, son derece başarılı biridir, bu anlamda tavsiye ederim, zaten çevremde de tavsiye ediyorum, ben covid sonrası 1 yada 1 buçuk yılı aşkın kendisinden seans aldım ve 24 kilo verdim, 94 kilodan 73 kilolara kadar düştüm, her yönden çok güzel etkisini gördüm, hayatımı daha kaliteli yaşamaya başladım, psikolojik olarak da kendimi çok mutlu hissettim, insülin direncim nedeniyle şuan bir yükselme ile karşı karşıyayım ama bu benimle alakalı bir durum, iş yaşamımın da bunun üzerinde etkisi var, onun dışında kendisi bana yaşam kültürü de kazandı, hala kendisinin söylediği şeyleri uyguluyorum, artık kendisinden seans almıyorum, ailemizden bir diyetisyen çıktı ve kendisinin onayıyla da seanslarımı sonlandırdım.",
          "date": "30 Ocak 2023"
        },
        {
          "author": "z....e",
          "text": "Kendisi çok ilgili bir uzman, ne zaman mesaj atarsam kendisinden cevap alabiliyorum, son derece anlayışlı, güler yüzlü ve samimi biri, seans almaya başlayalı neredeyse 2 ay olacak, kendisinden çok memnunum, kilo verme üzerine seans alıyorum, benim aynı zamanda mide sıkıntılarım da var, onun üzerine de çalışmalar yapıyoruz, seans almaya 70 kiloyla başladım, yakın zamanda tartılmadım ama yaklaşık olarak 4 kilo verdim, kendisi diyet listelerini her insanın evinde olabilecek besinler üzerinden yapıyor, ulaşılması zor ya da pahalı listeler vermiyor, bundan dolayı diyet listeleriyle alakalı da herhangi bir uyum sorunu yaşamıyorum.",
          "date": "29 Ocak 2023"
        },
        {
          "author": "ö....ç",
          "text": "Kilo vermek üzerine kendisiyle görüşmelerimiz oluyor, kendisi son derece ilgili, işinde başarılı ve işini layıkıyla yapıyor, şuan seanslarımız iyi gidiyor, 1 ay gibi bir süredir birlikteyiz, bu süreçte 84 kiloydum, 4-5 kilo verdim, son derece anlayışlı biri, diyet listelerine uyum sağlamakta da herhangi bir zorluk yaşamıyorum.",
          "date": "29 Ocak 2023"
        },
        {
          "author": "f....t",
          "text": "Kendisi en başta çok tatlı bir insan, uzman olarak da mükemmel, çok memnunum, kilo vermek üzerine destek aldım, yaklaşım olarak da ilgisi çok fazla, mesaj atmadığım zamanlar kendisi her zaman uyarı yapıyor, neden atmadın diye soruyor, ne yediğim konusunda bilgi alıyor, bunun dışında kendimi seanslarda çok rahat hissediyorum, ilk zamanlar kendisine online olarak başlamıştım, şimdi iki aydır yüz yüze seans alıyorum, kendisinden seans aldığım süre içerisinde 7-8 kilo verdim, 67 kilo oldum, daha sonra seansları bıraktım ve sonrasında diyet yapmadım, kullandığım antidepresanların etkisiyle de kilo alma durumum oldu, şuan seanslarımıza yine devam ediyoruz.",
          "date": "28 Ocak 2023"
        },
        {
          "author": "a....y",
          "text": "Ben bağırsak rahatsızlığımdan dolayı, bağırsak tedavisi için kendisinden seans aldım, bu süreçte bana karşı yaklaşımı, önerileri ve yöntemleriyle kendisi sağlığıma kavuşmamı sağladı, en başta rahatsızlığımı direk tespit etti, o noktada bana bir tedavi uyguladı ve ben uyguladığı tedavinin üçüncü gününde olumlu etkisini fark ettim.",
          "date": "28 Ocak 2023"
        },
        {
          "author": "s....ç",
          "text": "Kendisiyle çalışmaya başlayalı 2 ay oldu, çok güleryüzlü, hoş bir bayan, güzel de ilerliyoruz, kilo vermek amaçlı destek alıyorum ama aslında kilo vermekten ziyade ben 50 yaşında bir bayanım, bu yaşlarda menopoz dönemi gibi sebeplerden dolayı hormonlar çok değişiyor ve dengeleri bozuluyor, bu anlamda daha çok sağlıklı beslenme üzerine kendisinden seans alıyorum, gayet güzel ve sağlıklı gidiyor, verdiği tariflerde gerçekten günlük hayatımda evimde çok rahatlıkla yapılabilecek öğünlerden oluşuyor, günlük hayatta kullanabilen bir liste hazırlıyor, dışarıdan hiç bir şey almadan evdeki malzemelerle listelerime uyum sağlayabiliyorum, o konuda çok mutluyum.",
          "date": "27 Ocak 2023"
        },
        {
          "author": "k.....",
          "text": "Buse hocama kan değerlerimin kötü olması nedeniyle doktorumun tavsiyesi üzerine başvurdum. Morelim çok bozuk ve umudum tükenmişken güler yüzü ve pozitif enerjisi sayesinde yeniden kendime inanmaya başladım ve sayesinde 24 kiloyu geride bıraktım. Kendisinin sağlıklı beslenmeye olan aşkı ve lezzetli tarifleriyle hiç diyetteymiş gibi hissetmedim aksine süreci hayatıma aşılayarak bana yemek yapmayı da sevdirdi. Karacigerim artık daha sağlıklı , şekerim dengede tahlillerim iyileşti. Yardımları için bir kez daha teşekkür ediyorum. Doktoruma da buse hocamla tanışmama vesile olduğu için ayrı teşekkür ediyorum.",
          "date": "12 Aralık 2022"
        },
        {
          "author": "c ...t",
          "text": "Buse hanım işini severek yapan çok özenli bir diyetisyen. Kendisi sayesinde 20 kilo verdim ve hayatım tamamen değişti. Daha önce pek çok diyetisyen deneyimim oldu ama hiç bu kadar takip edildiğimi hatırlamıyorum. Kendisiyle her gün iletişim halindeydik. Çalışma hayatım olduğu için tamamen bana uygun listeler hazırladı. Tadını sevmediğim hiçbir şeyi zorla vermedi. Hayatıma uygun şekilde listeler hazırladığı için hiç zorlanmadım. Ne zaman düşsem beni ayağa kaldırdı. O kadar iyi motive etti ki bırakmayı hiç düşünmedim. Kendisiyle iyi ki tanıştım. Sağlıklı bir hayat isteyen herkese gönülden tavsiyemdir.",
          "date": "12 Aralık 2022"
        },
        {
          "author": "ş....ç",
          "text": "Herkese tavsiye ediyorum. Kilo vermek ve sağlıklı beslenmek amacıyla başvurdum kendisine. Yeme alışkanlıklarınıza ve sevdiğiniz besinlere göre çok güzel bir diyet programı veriyor. Keyifle kilo veriyorsunuz.",
          "date": "8 Aralık 2022"
        },
        {
          "author": "g ...",
          "text": "Hamilelik kilolarımı, doğumumdan bir yıl geçmesine rağmen verememiştim. Buse hanımla emzirme sürecinde tanıştım. Sayesinde 25 kilo verdim Sadece liste verip gönderen diyetisyenler gibi değil yani, verdiği listeye uyuluyor mu diye takip eden bir diyetisyen Bendeki olağanüstü değişimi gören, Etrafımdaki birçok kişi Buse hanımın danışanı oldu bi süre sonra. Eğer sağlıklı beslenerek, aç kalmadan kilo vermek isterseniz hiç düşünmeden Buse hanımı tercih edebilirsiniz. Sorularınızı asla karşılıksız bırakmaz, en ince ayrıntısına kadar anlatır ve tarifleri muhteşem lezzetli",
          "date": "6 Aralık 2022"
        },
        {
          "author": "s....p",
          "text": "Güleryüzlü canayakın ve zayıflama sürecinde tam destek olan Buse Hanım' a çok çok teşekkür ediyorum.",
          "date": "3 Aralık 2022"
        },
        {
          "author": "m.....",
          "text": "Buse Hanım'la 1 sene beraber çalıştık. 20 senedir obezitem vardı zayıflamayı başardım. Kendisine teşekkürlerimi iletiyorum. Diyet listelerimi 1 hafta 10 günde bir değiştirdiği için zorluk yaşamadım zorluk yaşadığım konularda değiştirdi ve bana özel hazırladı. İnsülin ve şeker problemim vardı diyetle birlikte onlardan da kurtuldum. Buse Hanım fazlasıyla ilgili, fazlasıyla yardımcı olan bir uzman. Hayatımda başaramadığım şeyi kendisi başarmama yardımcı oldu.",
          "date": "30 Kasım 2022"
        },
        {
          "author": "b....",
          "text": "Buse Hanım çok memnun kaldığım bir diyetisyen. Kendisi danışanlarına inanılmaz derecede kıymet veriyor. Danışanlarının söyledikleri kendisi için çok kıymetli. Buse Hanım'la kilo vermek üzerine beraber çalıştık ve sayesinde hedefime ulaştım. Kendisi diyetisyenimden daha çok arkadaşım gibiydi. Diyet listelerimi sadece bana özel, isteğim doğrultusunda hazırladı. Düşüncelerime, isteklerime çok kıymet verdi. Kendisinden aldığım danışmanlık hizmetinden memnun kaldım.",
          "date": "30 Kasım 2022"
        },
        {
          "author": "m.....",
          "text": "Buse Hanım'la yaklaşık 6 ay görüştüm. Kendisiyle çalışmaya başladığımda ofisi yoktu online olarak görüşüyorduk. Buse Hanım çok yardımsever, çok anlayışlı, çözüm odaklı çok iyi bir diyetisyen çok iyi bir insan. Kendisiyle çalışmaktan çok memnunum. Çevremde kendisiyle çalışan o kadar insan var ki. Kendisi disiplindir ve her konuda yardımcı olur. Mutlaka b planı vardır ve işinde iyidir. Hem çevremde hem de sosyal medyada Buse Hanım'ı tavsiye ediyorum. Diyet listelerimi kan tahlillerime göre 10'ar günlük listeler şeklinde herkesin kendisine yaşam şekline alışkanlıklarına göre hazırlıyor. Ben kahvaltı yapmayı sevmeyen birisiydim sayesinde kahvaltı yapmaya alıştım. Her öğünde yediklerimin fotoğrafını istedi. Neyin yerine neyi kullanabiliriz diyerek yardımcı oldu. Her zaman ulaşılabilirdi yazdığımda zaman hemen dönüş yapar. Buse Hanım'la 6 ayda 21 kilo verdim. 1 buçuk sene oldu ve hala kilomu koruyorum. Kendisiyle çalıştığım için çok memnunum.",
          "date": "30 Kasım 2022"
        },
        {
          "author": "m.....",
          "text": "Buse Hanım'dan gayet memnunum. Daha öncesinde hiç kimseden bu kadar memnun kalmamıştım. Kilo vermek üzerine kendisiyle görüşme sağlıyorum ve gayet güzel gidiyor. Hipoglisemim var diyet listelerimi hazırlarken bana göre ve kan değerlerime bakarak hazırlıyor uygularken zorluk yaşamıyorum aç kalmıyorum. Daha öncesinde çok fazla diyetisyenle çalıştım ama Buse Hanım gibisine denk gelmemiştim. Kendisi işini çok severek yapan bunu size sürekli hissettiren ve alanında çok iyi olduğunu düşündüğüm bir uzman.",
          "date": "29 Kasım 2022"
        },
        {
          "author": "n.....",
          "text": "Buse Hanım'la çalıştığım süreç boyunca çok memnun kaldım. Benim için faydalı oldu. Kendisiyle kilo vermek amacıyla görüşme sağladım ve 5 ay danışmanlık hizmeti aldım. Diyet listelerimi bana özel hazırladı. Bağırsak problemlerden dolayı kendisiyle eliminasyon surecide yapmıştık sonrasında şikayetlerim geriledi. Buse Hanım çok neşeli birisi. Kendisi olumsuz bir şey söylemiyor insanlar diyet sürecinde düşürebiliyorlar kendisi çok yardımcı oluyor. Buse Hanım'la mantıklı bir şekilde ilerleyebildik.",
          "date": "29 Kasım 2022"
        },
        {
          "author": "ö.....",
          "text": "Buse Hanım'la online olarak çalışıyoruz ve gayet iyi kilo verdim. 20 kiloyu sağlıklı bir şekilde verdim. 10 günde bir değişin diyet listelerimiz var ve uygularken hiç zorlanmadım. Hem sağlığıma kavuştum hem de gereken kilomu verdim. Neşeli bir şekilde sürecime devam ediyorum. İlk başta sağlık sorunum vardı, kan değerlerim yüksekti onlarda değişme oldu. Şekerim, kolesterolüm düştü. Sağlığıma kavuştuğumu diyebilirim. Buse Hanım işinde gayet profesyonel bir uzman. Kendisi motivasyon bakımından yüksek o yüzden de kilo verimi gayet neşeli bir şekilde oluyor. Buse Hanım işinde çok saygılı ve disiplinli bir diyetisyen. Kendisini herkese öneririm.",
          "date": "28 Kasım 2022"
        },
        {
          "author": "s.....",
          "text": "Buse Hanım'dan danışmanlık hizmeti alan arkadaşım gözümüzün önünde inceldiğini görünce bende numarasını aldım ve görüşme sağladım. Kendisi sağ olsun dokunduğu her kişiyi kolaylıkla zayıflatıyor. Normalde diyete karşı bir insandım ama kendisiyle sürecimde hiç zorlanmadım çünkü zorunlulukları yok illa bunu şunu yiyeceksin diye bir şeyleri yok. Misafirliğe gittiğinizde menüde ne varsa alternatif olarak diyetinize uygun menüsü oluşturuyor o yüzden çok rahattı. Benden sonra eşim de Buse Hanım'dan faydalandı. Çok mutluyuz kendisinin kolaylığını söylüyoruz.",
          "date": "27 Kasım 2022"
        },
        {
          "author": "p....",
          "text": "Buse Hanım iyi bir diyetisyen. Kendisi çok yardımcı oluyor. Ne zaman mesaj yazsam geri dönüş sağlıyor. Diyet listelerimi uygularken zorluk yaşamıyorum yaşadığım yerleri söylüyorum ona göre hazırlıyor. Buse Hanım'la kilo vermek amacıyla görüşme sağladım. 13 kilo verdim 10 kilo daha vereceğim sürecime devam ediyorum. İnsülin direncim vardı diyetle birlikte şu anda geçti. Kendisinden aldığım danışmanlık hizmetinden memnunum.",
          "date": "26 Kasım 2022"
        },
        {
          "author": "ç....e",
          "text": "Buse Hanım son derece ilgili ve işini harika yapan bir diyetisyen. Kısa sürede hedef kiloya ulaşma konusunda çok güzel yol katettik.",
          "date": "24 Kasım 2022"
        },

      ]


    };
  },
  methods: {
    handlePhoneClick() {
      this.gtag_report_conversion('https://benita.buseturker.com/profile'); // Değiştirmeniz gereken URL'yi buraya ekleyin
    },
    scrollToAddress() {
      this.$refs.addressCard.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToComment() {
      this.$refs.commentCard.scrollIntoView({ behavior: 'smooth' });
    },
    loadMoreComment() {
      this.lastCommentIndex += this.commentIncrementValue
    },
    clickDoktorTakvimi() {
      window.location.href = 'https://www.doktortakvimi.com/buse-turker/diyetisyen/izmir';
    },
    clickBeslenmeArsivim() {
      window.location.href = 'https://www.instagram.com/beslenmearsivim/';
    },
    gtag_report_conversion() {
      const dataLayerObj = {
        'event': 'ClickPhone', // GTM'de tetikleyici olarak kullanacağınız özel olay adı
        'send_to': 'AW-11410114395/0uK8CMqNxfwYENuO4sAq', // Dönüşüm izleme etiketi ID'si
        'url': 'https://benita.buseturker.com/profile', // Değiştirmeniz gereken URL
        'id':  uuidv4()
      };

      // Veri katmanına özel olayı ekleyin
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerObj);
    }
  },
  components: { Comment },

  mounted() {
    this.$store.state.isAbsolute = false;
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    this.$store.state.showSidenav = false;
    this.$store.state.hideConfigButton = false;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
