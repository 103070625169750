import moment from 'moment';
import httpService from './httpService';
import {
  formatPhoneNumber
} from './utils/phoneUtils'

const createAppointment = async (selectedDate, selectedTime, elapsedTime, appointmentType, adviseeId) => {
  const startDate = new Date(selectedDate)
  startDate.setHours(Number(selectedTime.split(':')[0]))
  startDate.setMinutes(Number(selectedTime.split(':')[1]))
  const endDate = new Date(selectedDate)
  endDate.setMinutes(startDate.getMinutes() + elapsedTime)
  return httpService
    .post(`/appointment`, {
      "adviseeId": adviseeId,
      "type": appointmentType,
      "startDateTime": startDate.toISOString().split('T')[0] + "T" + startDate.toLocaleTimeString('en-US', { hour12: false }),
      "endDateTime": endDate.toISOString().split('T')[0] + "T" + endDate.toLocaleTimeString('en-US', { hour12: false }),
    })
}

const createAppointmentAndAdvisee = async (selectedDate, selectedTime, elapsedTime, selectedAdviseeId, appointmentType, newAdviseeRecord) => {
  const startDate = new Date(selectedDate)
  startDate.setHours(Number(selectedTime.split(':')[0]))
  startDate.setMinutes(Number(selectedTime.split(':')[1]))
  const endDate = new Date(selectedDate)
  endDate.setMinutes(startDate.getMinutes() + elapsedTime)
  return httpService
    .post(`/appointment/with-advisee`, {
      "appointment": {
        "adviseeId": selectedAdviseeId,
        "type": appointmentType,
        "startDateTime": startDate.toISOString().split('T')[0] + "T" + startDate.toLocaleTimeString('en-US', { hour12: false }),
        "endDateTime": endDate.toISOString().split('T')[0] + "T" + endDate.toLocaleTimeString('en-US', { hour12: false }),
      },
      "advisee": {
        "phone": formatPhoneNumber(newAdviseeRecord.phone),
        "name": newAdviseeRecord.name,
        "surname": newAdviseeRecord.surname
      }
    })
}

const updateAppointment = async (selectedDate, selectedTime, elapsedTime, appointmentId, selectedAdviseeId, appointmentType) => {
  var startDate = new Date(selectedDate)
  startDate = moment(startDate)
  startDate.set('hour', selectedTime.split(":")[0])
  startDate.set('minute', selectedTime.split(":")[1])
  startDate = startDate.toDate()
  var endDate = new Date(selectedDate)
  endDate = moment(endDate)
  endDate.set('hour', selectedTime.split(":")[0])
  endDate.set('minute', selectedTime.split(":")[1])
  endDate = endDate.add(elapsedTime, 'm').toDate();
  return httpService
    .put(`/appointment/${appointmentId}`, {
      "adviseeId": selectedAdviseeId,
      "type": appointmentType,
      "startDateTime": startDate.toISOString().split('T')[0] + "T" + startDate.toLocaleTimeString('en-US', { hour12: false }),
      "endDateTime": endDate.toISOString().split('T')[0] + "T" + endDate.toLocaleTimeString('en-US', { hour12: false }),
    })
}

const deleteAppointment = async (appointmentId) => {
  return httpService
    .delete(`/appointment/${appointmentId}`)
}

const getAppointments = async (info) => {
  const [startDate,] = info.startStr.split("T");
  const [endDate,] = info.endStr.split("T");
  return httpService
    .get(`/appointment?start=${startDate}&end=${endDate}`)
    .then((response) => {
      return response.data.map(appointment => {
        return {
          "id": appointment.id,
          "title": appointment.adviseeName + " " + appointment.adviseeSurname,
          "start": appointment.startDateTime,
          "end": appointment.endDateTime,
          "type": appointment.type,
          "color": appointment.type == 1 ? '#64CCC5' : '#FFBA86',
          "classNames": appointment.type == 1 ? 'face-to-face' : 'online'

        }
      });
    })
    .catch((error) => {
      console.error('Hata:', error);
    });
}

const getAppointmentTimeOptions = () => {
  return [
    { "label": "15 dakika", "value": 15 },
    { "label": "30 dakika", "value": 30 },
    { "label": "45 dakika", "value": 45 },
    { "label": "1 saat", "value": 60 },
    { "label": "1 saat 15 dakika", "value": 75 },
    { "label": "1 saat 30 dakika", "value": 90 },
    { "label": "1 saat 45 dakika", "value": 105 },
    { "label": "2 saat", "value": 120 }
  ]
}

const getAppointmentTypes = () => {
  return [
    { "label": "Yüzyüze Görüşme", "value": 1 },
    { "label": "Online Görüşme", "value": 2 },
  ]
}



export {
  createAppointment, deleteAppointment, createAppointmentAndAdvisee, updateAppointment, getAppointments,
  getAppointmentTimeOptions, getAppointmentTypes
}