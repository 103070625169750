import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Advisee from "../views/Advisee.vue"
import Plan from "../views/Plan.vue"
import NewAdvisee from "../views/NewAdvisee"
import AdviseeDetail from "../views/AdviseeDetail"
import Appointment from "../views/Appointment.vue"
import NewPlan from "../views/NewPlan"
import NewPayment from "../views/NewPayment.vue"
import PaymentReport from "../views/PaymentReport.vue"

// import Billing from "../views/Billing.vue";
// import VirtualReality from "../views/VirtualReality.vue";
// import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import ConfirmCode from "../views/ConfirmCode.vue"
import ForgotPassword from "../views/ForgotPassword.vue"
import ForgotPasswordSubmit from "../views/ForgotPasswordSubmit.vue"
import EditProfile from "../views/EditProfile.vue"
import Comment from "../views/Comment.vue"
import Payment from "../views/Payment.vue"
import CommentDetail from "../views/CommentDetail.vue"
import RequestComment from "../views/RequestComment.vue"
import tokenGuard from "./guards/token.guard"

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
    meta: {
      isAuthenticated : true
    }
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      isAuthenticated : true
    }
  },
  {
    path: "/advisee",
    name: "Danışanlar",
    component: Advisee,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/plan",
    name: "Paketler",
    component: Plan,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/new-advisee",
    name: "Yeni Danışan",
    component: NewAdvisee,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/advisee/:id",
    name: "Danışan Detay",
    component: AdviseeDetail,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/new-plan",
    name: "Yeni Paket",
    component: NewPlan,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/appointment",
    name: "Randevular",
    component: Appointment,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/payment",
    name: "Gelirlerim",
    component: Payment,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/payment-report",
    name: "Gelir Raporu",
    component: PaymentReport,
    meta: {
      isAuthenticated: true
    }
  },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/virtual-reality",
  //   name: "Virtual Reality",
  //   component: VirtualReality,
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  // },
   {
     path: "/profile",
     name: "Profile",
     component: Profile,
   },
   {
    path: "/edit-profile",
    name: "Profili Düzenle",
    component: EditProfile,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/comment",
    name: "Yorumlar",
    component: Comment,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/comment/:id",
    name: "Yorum Detay",
    component: CommentDetail,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/comment-request/:id",
    name: "Yorum Girişi",
    component: RequestComment,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/confirmcode",
    name: "Confirmcode",
    component: ConfirmCode,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/forgot-password-submit",
    name: "ForgotPasswordSubmit",
    component: ForgotPasswordSubmit,
  },
  {
    path: "/new-payment",
    name: "Yeni Ödeme",
    component: NewPayment,
    meta: {
      isAuthenticated: true
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  tokenGuard(to, from, next)
})
export default router;
