<template>
    <div class="avatar-menu">
        <el-dropdown placement="bottom-end" trigger="click" @command="handleCommand">
            <el-avatar :src="avatarUrl" shape="circle" size="small">
            </el-avatar>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item command="logout">
                        <div>
                            <i class="fas fa-sign-out-alt"></i>
                            <span style="margin-left: 5px;">Çıkış yap</span>
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>
  
<script>
import { Auth } from 'aws-amplify';

export default {
    data() {
        return {
            showMenu: true,
            username: 'kullanici_adi', // Kullanıcı adını buraya ekle
            avatarUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png', // Avatar bağlantısını buraya ekle
            hide: true
        };
    },
    methods: {
        async handleCommand(command) {
            if (command === 'logout') {
                await Auth.signOut();
                this.$router.push("/signin")
            }
        },
    }
};
</script>
  
<style>
/* Element Plus stillerini içe aktarabilirsin */

.avatar-menu {
    position: relative;
    display: inline-block;
}
</style>
  