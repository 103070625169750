<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ cardTitle }}</h6>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group" :class="this.$store.state.isRTL ? 'pe-0' : ''">
        <li
          class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg"
          :class="this.$store.state.isRTL ? 'pe-0' : 'ps-0'"
        >
          <div class="d-flex align-items-center">
            <div
              class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
              :class="this.$store.state.isRTL ? 'ms-3' : 'me-3'"
            >
              <i class="text-white opacity-10 " :class="isOnline1 ? 'fas fa-globe': 'fas fa-people-arrows'"></i>
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ title }}</h6>
              <span class="text-xs">
                {{ titleDesc }}
                <span class="font-weight-bold">{{ titleDesc2 }}</span>
              </span>
            </div>
          </div>
          <div class="d-flex">
            <button
              class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i class="ni ni-bold-right" aria-hidden="true"></i>
            </button>
          </div>
        </li>
        <li
          class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg"
          :class="this.$store.state.isRTL ? 'pe-0' : 'ps-0'"
        >
          <div class="d-flex align-items-center">
            <div
              class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
              :class="this.$store.state.isRTL ? 'ms-3' : 'me-3'"
            >
              <i class="text-white opacity-10" :class="isOnline2 ? 'fas fa-globe': 'fas fa-people-arrows'"></i>
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ title2 }}</h6>
              <span class="text-xs">
                {{ title2Desc }}
                <span class="font-weight-bold">{{ title2Desc2 }}</span>
              </span>
            </div>
          </div>
          <div class="d-flex">
            <button
              class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i class="ni ni-bold-right" aria-hidden="true"></i>
            </button>
          </div>
        </li>
        <li
          class="border-0 list-group-item d-flex justify-content-between border-radius-lg"
          :class="this.$store.state.isRTL ? 'pe-0' : 'ps-0'"
        >
          <div class="d-flex align-items-center">
            <div
              class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
              :class="this.$store.state.isRTL ? 'ms-3' : 'me-3'"
            >
              <i class="text-white opacity-10" :class="isOnline3 ? 'fas fa-globe': 'fas fa-people-arrows'"></i>
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ title3 }}</h6>
              <span class="text-xs">
                {{ title3Desc }}
                <span class="font-weight-bold">{{ title3Desc2 }}</span>
              </span>
            </div>
          </div>
          <div class="d-flex">
            <button
              class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i class="ni ni-bold-right" aria-hidden="true"></i>
            </button>
          </div>
        </li>
        <li
          class="border-0 list-group-item d-flex justify-content-between border-radius-lg"
          :class="this.$store.state.isRTL ? 'pe-0' : 'ps-0'"
        >
          <div class="d-flex align-items-center">
            <div
              class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
              :class="this.$store.state.isRTL ? 'ms-3' : 'me-3'"
            >
              <i class="text-white opacity-10" :class="isOnline4 ? 'fas fa-globe': 'fas fa-people-arrows'"></i>
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ title4 }}</h6>
              <span class="text-xs">
                {{ title4Desc }}
                <span class="font-weight-bold">{{ title4Desc2 }}</span>
              </span>
            </div>
          </div>
          <div class="d-flex">
            <button
              class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i class="ni ni-bold-right" aria-hidden="true"></i>
            </button>
          </div>
        </li>
        <li
          class="border-0 list-group-item d-flex justify-content-between border-radius-lg"
          :class="this.$store.state.isRTL ? 'pe-0' : 'ps-0'"
        >
          <div class="d-flex align-items-center">
            <div
              class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
              :class="this.$store.state.isRTL ? 'ms-3' : 'me-3'"
            >
              <i class="text-white opacity-10" :class="isOnline5 ? 'fas fa-globe': 'fas fa-people-arrows'"></i>
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ title5 }}</h6>
              <span class="text-xs">
                {{ title5Desc }}
                <span class="font-weight-bold">{{ title5Desc2 }}</span>
              </span>
            </div>
          </div>
          <div class="d-flex">
            <button
              class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i class="ni ni-bold-right" aria-hidden="true"></i>
            </button>
          </div>
        </li>
        <li
          class="border-0 list-group-item d-flex justify-content-between border-radius-lg"
          :class="this.$store.state.isRTL ? 'pe-0' : 'ps-0'"
        >
          <div class="d-flex align-items-center">
            <div
              class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
              :class="this.$store.state.isRTL ? 'ms-3' : 'me-3'"
            >
              <i class="text-white opacity-10" :class="isOnline6 ? 'fas fa-globe': 'fas fa-people-arrows'"></i>
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ title6 }}</h6>
              <span class="text-xs">
                {{ title6Desc }}
                <span class="font-weight-bold">{{ title6Desc2 }}</span>
              </span>
            </div>
          </div>
          <div class="d-flex">
            <button
              class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i class="ni ni-bold-right" aria-hidden="true"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "categories-card",
  props: {
    cardTitle: {
      type: String,
      default: "Bugünkü Randevular",
    },
    title: {
      type: String,
      default: "Ozan Türker",
    },
    title2: {
      type: String,
      default: "Buse Türker",
    },
    title3: {
      type: String,
      default: "Ece Tüngür",
    },
    title4: {
      type: String,
      default: "Gülfinaz Türker",
    },
    title5: {
      type: String,
      default: "Mehmet Fatih Özgüven",
    },
    title6: {
      type: String,
      default: "Uğur Ali Kadir Sun",
    },
    titleDesc: {
      type: String,
      default: "14:30 Randevusu",
    },
    titleDesc2: {
      type: String,
      default: "Yeni Danışan",
    },
    title2Desc: {
      type: String,
      default: "16:00 Randevusu",
    },
    title2Desc2: {
      type: String,
      default: "",
    },
    title3Desc: {
      type: String,
      default: "17:00 Randevusu",
    },
    title3Desc2: {
      type: String,
      default: "",
    },
    title4Desc: {
      type: String,
      default: "21:00 Randevusu",
    },
    title4Desc2: {
      type: String,
      default: "Yeni Danışan",
    },
    title5Desc: {
      type: String,
      default: "21:45 Randevusu",
    },
    title5Desc2: {
      type: String,
      default: "",
    },
    title6Desc: {
      type: String,
      default: "22:00 Randevusu",
    },
    title6Desc2: {
      type: String,
      default: "",
    },
    isOnline1: {
      type: Boolean,
      default: false
    },
    isOnline2: {
      type: Boolean,
      default: true
    },
    isOnline3: {
      type: Boolean,
      default: false
    },
    isOnline4: {
      type: Boolean,
      default: false
    },
    isOnline5: {
      type: Boolean,
      default: false
    },
    isOnline6: {
      type: Boolean,
      default: true
    },
  },
};
</script>
