<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-10">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Yeni Paket Ekle</p>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Paket Bilgileri</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Plan Adı</label
                  >
                  <el-input v-model="this.planName" size="large" />

                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Süre (gün)</label
                  >
                  <el-input v-model="duration" size="large" />

                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Ücret</label
                  >
                  <el-input v-model="this.price" size="large">
            <template #append>
              <i class="fas fa-lira-sign"></i>
            </template>
          </el-input>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Görüşme Sayısı</label
                  >
                  <el-input v-model="this.appointmentCount" size="large" />
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-md-2">
                  <argon-button @click="savePlan" color="success" size="sm" class="ms-auto"
                  >Kaydet</argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";


const body = document.getElementsByTagName("body")[0];

export default {
  name: "new-plan",
  data() {
    return {
      showMenu: false,
      planName: null,
      price: null,
      duration: null,
      appointmentCount: null,
    };
  },
  methods : {
    savePlan() {
      this.$http
        .post(`/plan`, {
          "name": this.planName,
          "price": this.price,
          "planDuration": this.duration,
          "plannedAppointmentCount": this.appointmentCount,
        })
        .then(() => {
          this.$router.push("/plan")
        })
    }
  },
  components: { ArgonButton},

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
