import { createApp } from "vue";
import vSelect from 'vue-select'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import { Amplify } from 'aws-amplify';
import axios from 'axios';
// import the package
import ElementPlus from 'element-plus'
import tr from 'element-plus/dist/locale/tr.mjs'
import 'dayjs/locale/zh-cn'
import { Auth } from 'aws-amplify';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import * as Sentry from "@sentry/vue";





// import the necessary css file
import "vue-awesome-paginate/dist/style.css";
import 'vue-select/dist/vue-select.css';
import 'element-plus/dist/index.css'
import "./assets/css/main.css"




const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus, { locale: tr });
appInstance.component('vSelect', vSelect)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  appInstance.component(key, component)
}
appInstance.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBQH_IZI-1Gdxt-uoclBiBkRgrXBUdkFqU',
  },
})



// Axios instance oluşturun ve Vue globaline ekleyin
const axiosInstance = axios.create({
  baseURL: 'https://api-beta.buseturker.com', // İsteğinizi yapacağınız API base URL'i
});

// Axios interceptor'ı oluşturun
axiosInstance.interceptors.request.use(
  (config) => {
    // JWT token'ınızı burada alın (örneğin localStorage'den)
    const email = localStorage.getItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.LastAuthUser')
    var jwtToken = localStorage.getItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.' + email + '.accessToken');
    // Eğer token varsa, Authorization header'ına ekleyin
    if (jwtToken) {
      config.headers['Authorization'] = `Bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // 401 hatası alındığında ve yeniden deneme yapılmamışsa
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Yeni bir token almak için Cognito'ya istek yapın
        const email = localStorage.getItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.LastAuthUser')
        const refreshedToken = await refreshAuthToken(); // Bu fonksiyonun tanımlanması gerekir
        localStorage.setItem('CognitoIdentityServiceProvider.46ljgbccl5vpdb61c6vsodtnu8.' + email + '.accessToken', refreshedToken);

        // Yenilenen token ile orijinal isteği tekrar gönderin
        originalRequest.headers['Authorization'] = `Bearer ${refreshedToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Token yenileme başarısız olursa, kullanıcıyı çıkış yapmaya zorlayabilirsiniz
        // veya başka bir işlem yapabilirsiniz.
        // Örnek:
        console.error('Token refresh failed', refreshError);
        await logoutUserAndRedirect();
        // Kullanıcıyı çıkış yapmaya yönlendirme gibi bir işlem
      }
    }
    return Promise.reject(error);
  }
);

async function refreshAuthToken() {
  // Cognito'dan yeni bir token almak için gerekli isteği yapın
  // Örnek bir Axios isteği:
  await Auth.currentAuthenticatedUser();
  const refreshedSession = await Auth.currentSession();
  return refreshedSession.getAccessToken().getJwtToken();

  // Burada token yenileme işlemi için gerekli isteği oluşturun ve gönderin
  // Detaylarına Cognito API belgelerinden bakabilirsiniz
}

async function logoutUserAndRedirect() {
  try {
    // Kullanıcıyı çıkış yapmaya zorlayın (örneğin, Amplify ile)
    await Auth.signOut();

    // Kullanıcı çıkış yaptıktan sonra yönlendirilecek sayfa veya işlem
    router.push('/sign-in'); // Örnek olarak çıkış yaptıktan sonra gidecek sayfanın adresini verin
  } catch (error) {
    console.error('Logout failed', error);
    // Çıkış işlemi başarısız oldu, başka bir işlem yapabilirsiniz
  }
}

// Axios instance'ını Vue globaline ekleyin
appInstance.config.globalProperties.$http = axiosInstance;

Sentry.init({
  appInstance,
  dsn: "https://a1f16531eeb644ba1e30a4c9fea857d3@o1038578.ingest.sentry.io/4506350302593024",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://benita.buseturker.com/*"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: ['https://api-beta.buseturker.com/*'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});




appInstance.mount("#app");


Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_G7cCoDSV6',
    userPoolWebClientId: '46ljgbccl5vpdb61c6vsodtnu8',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: 'https://nutr123.auth.eu-central-1.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'http://localhost:8080',
      redirectSignOut: 'http://localhost:8080',
      responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

export default appInstance;

