<template>
    <div class="comment">
      <div class="comment-content">
        <el-avatar size="small">{{ author.charAt(0).toUpperCase() }}</el-avatar>
        <div class="comment-info">
          <span class="comment-author">{{ author }}</span>
          <span class="comment-date">{{ date }}</span>
        </div>
      </div>
      <div class="comment-text">
        {{ text }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      author: String,
      date: String,
      text: String
    }
  };
  </script>
  
  <style>
  /* Bu stil kodunu Vue bileşeninizin stil bölümüne veya global stil dosyanıza ekleyebilirsiniz. */
  .comment {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc; /* Yorumlar arasına çizgi eklemek için */
    padding-bottom: 15px; /* İhtiyaca göre ayarlayabilirsiniz */
  }
  
  .comment-content {
    display: flex;
    align-items: flex-start; /* Yükseklik farkını kaldırmak için */
    margin-bottom: 10px;
  }
  
  .comment-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column; /* İçeriği sütun olarak düzenle */
  }
  
  .comment-author {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .comment-date {
    font-size: 12px;
    margin-top: 5px; /* Tarihi biraz aşağıda göstermek için */
  }
  
  .comment-text {
    margin-left: 30px; /* İhtiyaca göre ayarlayabilirsiniz */
    color: #333; /* İhtiyaca göre renk ayarı yapabilirsiniz */
  }
  </style>
  