<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-10">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Yeni Gelir</p>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <el-form label-position="top">
                    <el-form-item label="Danışan">
                      <el-select id="advisee_name_filter" v-model="selectedAdvisee" filterable remote size="large"
                        reserve-keyword placeholder="Danışan ismi giriniz" :remote-method="getAdviseesBySearchKey"
                        :loading="loading" style="width: 100%">
                        <el-option v-for="adviseeItem in adviseeApiResult" :key="adviseeItem.id"
                          :label="adviseeItem.name" :value="adviseeItem.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Alınan Plan">
                      <el-select style="width: 100%;" v-model="this.selectedPlan" value-key="id" size="large">
                        <el-option id="planSelect" v-for="item in plan.items" :key="item.id" :label="item.name"
                          :value="item" />
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Ödeme Tarihi">
                      <el-date-picker id="planStartDate" style="width: 100%;" v-model="this.paymentDate" type="date"
                        size="large" format="DD.MM.YYYY" value-format="DD.MM.YYYY" />
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-md-2">
                  <argon-button @click="savePaymentLog" color="success" size="sm" class="ms-auto">Kaydet</argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "new-plan",
  data() {
    return {
      showMenu: false,
      planName: null,
      price: null,
      duration: null,
      appointmentCount: null,
      incomeOrCost: "1",
      selectedAdvisee: null,
      selectedPlan: null,
      loading: true,
      activePage: 1,
      paymentDate: null,
      adviseeApiResult: [],
      advisee: {
        tableTitle: "Danışanlar",
        column1: "Ad Soyad",
        column2: "Durum",
        column3: "Paket",
        column4: "Başlangıç Tarihi",
        items: [],
        totalCount: 0,
        totalPages: 0,

      },
      plan: {
        items: []
      }
    };
  },
  methods: {
    getAdviseesBySearchKey(searchKey) {
      this.$http
        .get(`/advisee?searchKey=${searchKey}`)
        .then((response) => {
          this.adviseeApiResult = response.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name + " " + item.surname,
              logo: item.type == 1 ? "fas fa-globe" : "fas fa-people-arrows",
              phone: item.phone,
              status: item.status == 1 ? 'Aktif' : 'Pasif',
              package: item.planName,
              startDate: item.planStartDate,
              detailLink: "/advisee/" + item.id
            }
          })
          this.loading = false
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    fetchData() {
      return this.$http
        .get(`/plan?page=${this.activePage - 1}`)
        .then((response) => {
          console.log(response.data)
          const arr = []
          response.data.data.forEach((item) => {
            const plan = {
              id: item.id,
              name: item.name,
              nutritionistId: item.nutritionistId,
              plannedAppointmentCount: item.plannedAppointmentCount,
              price: item.price,
              status: item.status == 1 ? "Aktif" : "Pasif",
              type: item.status == 1 ? "success" : "info",
              duration: item.duration,

            }
            arr.push(plan)
          })
          this.plan.items = arr
          this.plan.totalCount = response.data.totalCount
          this.plan.totalPages = response.data.totalPages
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
    savePaymentLog() {
      return this.$http
        .post(`payment-log/advisee/${this.selectedAdvisee}`,
          {
            "price": this.selectedPlan.price,
            "paymentDate": this.paymentDate,
            "planId":this.selectedPlan.id,
            "planName":this.selectedPlan.name
          })
        .then((response) => {
          console.log(response.data)
          this.$router.push("/payment")
        })
        .catch((error) => {
          console.error('Hata:', error);
        });
    },
  },
  created() {
    this.fetchData();
  },
  components: { ArgonButton },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
