const formatPhoneNumber = (phoneStr) => {
    let phone = phoneStr
    if (phone.startsWith('+90')) {
        phone = phone.replace('+90', '')
    }
    if (phone.startsWith('0')) {
        phone = phone.replace('0', '')
    }
    phone = phone.replace(/\s/g, '');
    return phone
}

export { formatPhoneNumber, }